/*
* Bower party (from bower_components)
*/
@import "../../bower_components/OwlCarousel2/dist/assets/owl.carousel";

/*
* Fonts party (from src/fonts)
*/
@import "../fonts/fonts";

/*
* Custom (from src/style/partials)
*/
@import "partials/app";
