@font-face {
  font-family: 'BankGothicRUSSMedium';
  src: url('../fonts/BankGothicRUSSMedium.eot?#iefix') format('embedded-opentype'),  url('../fonts/BankGothicRUSSMedium.woff') format('woff'), url('../fonts/BankGothicRUSSMedium.ttf')  format('truetype'), url('../fonts/BankGothicRUSSMedium.svg#BankGothicRUSSMedium') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Norpeth-BlackItalic';
  src: url('../fonts/Norpeth-BlackItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/Norpeth-BlackItalic.otf')  format('opentype'),
  url('../fonts/Norpeth-BlackItalic.woff') format('woff'), url('../fonts/Norpeth-BlackItalic.ttf')  format('truetype'), url('../fonts/Norpeth-BlackItalic.svg#Norpeth-BlackItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Norpeth-Black';
  src: url('../fonts/Norpeth-Black.eot?#iefix') format('embedded-opentype'),  url('../fonts/Norpeth-Black.otf')  format('opentype'),
  url('../fonts/Norpeth-Black.woff') format('woff'), url('../fonts/Norpeth-Black.ttf')  format('truetype'), url('../fonts/Norpeth-Black.svg#Norpeth-Black') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Norpeth-BoldItalic';
  src: url('../fonts/Norpeth-BoldItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/Norpeth-BoldItalic.otf')  format('opentype'),
  url('../fonts/Norpeth-BoldItalic.woff') format('woff'), url('../fonts/Norpeth-BoldItalic.ttf')  format('truetype'), url('../fonts/Norpeth-BoldItalic.svg#Norpeth-BoldItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Norpeth-Bold';
  src: url('../fonts/Norpeth-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/Norpeth-Bold.otf')  format('opentype'),
  url('../fonts/Norpeth-Bold.woff') format('woff'), url('../fonts/Norpeth-Bold.ttf')  format('truetype'), url('../fonts/Norpeth-Bold.svg#Norpeth-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Norpeth-Book';
  src: url('../fonts/Norpeth-Book.eot?#iefix') format('embedded-opentype'),  url('../fonts/Norpeth-Book.otf')  format('opentype'),
  url('../fonts/Norpeth-Book.woff') format('woff'), url('../fonts/Norpeth-Book.ttf')  format('truetype'), url('../fonts/Norpeth-Book.svg#Norpeth-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Norpeth-DemiBoldItalic';
  src: url('../fonts/Norpeth-DemiBoldItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/Norpeth-DemiBoldItalic.otf')  format('opentype'),
  url('../fonts/Norpeth-DemiBoldItalic.woff') format('woff'), url('../fonts/Norpeth-DemiBoldItalic.ttf')  format('truetype'), url('../fonts/Norpeth-DemiBoldItalic.svg#Norpeth-DemiBoldItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Norpeth-DemiBold';
  src: url('../fonts/Norpeth-DemiBold.eot?#iefix') format('embedded-opentype'),  url('../fonts/Norpeth-DemiBold.otf')  format('opentype'),
  url('../fonts/Norpeth-DemiBold.woff') format('woff'), url('../fonts/Norpeth-DemiBold.ttf')  format('truetype'), url('../fonts/Norpeth-DemiBold.svg#Norpeth-DemiBold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Norpeth-ExtraBoldItalic';
  src: url('../fonts/Norpeth-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/Norpeth-ExtraBoldItalic.otf')  format('opentype'),
  url('../fonts/Norpeth-ExtraBoldItalic.woff') format('woff'), url('../fonts/Norpeth-ExtraBoldItalic.ttf')  format('truetype'), url('../fonts/Norpeth-ExtraBoldItalic.svg#Norpeth-ExtraBoldItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Norpeth-ExtraBold';
  src: url('../fonts/Norpeth-ExtraBold.eot?#iefix') format('embedded-opentype'),  url('../fonts/Norpeth-ExtraBold.otf')  format('opentype'),
  url('../fonts/Norpeth-ExtraBold.woff') format('woff'), url('../fonts/Norpeth-ExtraBold.ttf')  format('truetype'), url('../fonts/Norpeth-ExtraBold.svg#Norpeth-ExtraBold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Norpeth-HeavyItalic';
  src: url('../fonts/Norpeth-HeavyItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/Norpeth-HeavyItalic.otf')  format('opentype'),
  url('../fonts/Norpeth-HeavyItalic.woff') format('woff'), url('../fonts/Norpeth-HeavyItalic.ttf')  format('truetype'), url('../fonts/Norpeth-HeavyItalic.svg#Norpeth-HeavyItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Norpeth-Heavy';
  src: url('../fonts/Norpeth-Heavy.eot?#iefix') format('embedded-opentype'),  url('../fonts/Norpeth-Heavy.otf')  format('opentype'),
  url('../fonts/Norpeth-Heavy.woff') format('woff'), url('../fonts/Norpeth-Heavy.ttf')  format('truetype'), url('../fonts/Norpeth-Heavy.svg#Norpeth-Heavy') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Norpeth-Italic';
  src: url('../fonts/Norpeth-Italic.eot?#iefix') format('embedded-opentype'),  url('../fonts/Norpeth-Italic.otf')  format('opentype'),
  url('../fonts/Norpeth-Italic.woff') format('woff'), url('../fonts/Norpeth-Italic.ttf')  format('truetype'), url('../fonts/Norpeth-Italic.svg#Norpeth-Italic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Norpeth-LightItalic';
  src: url('../fonts/Norpeth-LightItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/Norpeth-LightItalic.otf')  format('opentype'),
  url('../fonts/Norpeth-LightItalic.woff') format('woff'), url('../fonts/Norpeth-LightItalic.ttf')  format('truetype'), url('../fonts/Norpeth-LightItalic.svg#Norpeth-LightItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Norpeth-Light';
  src: url('../fonts/Norpeth-Light.eot?#iefix') format('embedded-opentype'),  url('../fonts/Norpeth-Light.otf')  format('opentype'),
  url('../fonts/Norpeth-Light.woff') format('woff'), url('../fonts/Norpeth-Light.ttf')  format('truetype'), url('../fonts/Norpeth-Light.svg#Norpeth-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Norpeth-MediumItalic';
  src: url('../fonts/Norpeth-MediumItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/Norpeth-MediumItalic.otf')  format('opentype'),
  url('../fonts/Norpeth-MediumItalic.woff') format('woff'), url('../fonts/Norpeth-MediumItalic.ttf')  format('truetype'), url('../fonts/Norpeth-MediumItalic.svg#Norpeth-MediumItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Norpeth-Medium';
  src: url('../fonts/Norpeth-Medium.eot?#iefix') format('embedded-opentype'),  url('../fonts/Norpeth-Medium.otf')  format('opentype'),
  url('../fonts/Norpeth-Medium.woff') format('woff'), url('../fonts/Norpeth-Medium.ttf')  format('truetype'), url('../fonts/Norpeth-Medium.svg#Norpeth-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Norpeth-UltraItalic';
  src: url('../fonts/Norpeth-UltraItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/Norpeth-UltraItalic.otf')  format('opentype'),
  url('../fonts/Norpeth-UltraItalic.woff') format('woff'), url('../fonts/Norpeth-UltraItalic.ttf')  format('truetype'), url('../fonts/Norpeth-UltraItalic.svg#Norpeth-UltraItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Norpeth-Ultra';
  src: url('../fonts/Norpeth-Ultra.eot?#iefix') format('embedded-opentype'),  url('../fonts/Norpeth-Ultra.otf')  format('opentype'),
  url('../fonts/Norpeth-Ultra.woff') format('woff'), url('../fonts/Norpeth-Ultra.ttf')  format('truetype'), url('../fonts/Norpeth-Ultra.svg#Norpeth-Ultra') format('svg');
  font-weight: normal;
  font-style: normal;
}