/*********     vars     *********/

$font-n-medium: 'Norpeth-Medium', sans-serif;
$font-n-book: 'Norpeth-Book', sans-serif;
$font-n-demibold: 'Norpeth-DemiBold', sans-serif;
$font-n-light: 'Norpeth-Light', sans-serif;
$font-bank: 'BankGothicRUSSMedium', sans-serif;
$main-color: #fcc313;

/*********     temp styles     *********/
#aura-icon {
  content: '';
  width: 3px;
  height: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  box-shadow: 0 0 44px 22px rgba(252, 194, 20, .53);
}
#arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 56px;
  width: 38px;
  height: 2px;
  background-color: #fff;
  transition: all .3s;
  &:before {
    content: "";
    position: absolute;
    top: -3px;
    right: -6px;
    width: 10px;
    height: 2px;
    background-color: #fff;
    transform: rotate(45deg);
  }
  &:after {
    content: "";
    position: absolute;
    top: 3px;
    right: -6px;
    width: 10px;
    height: 2px;
    background-color: #fff;
    transform: rotate(-45deg);
  }
}
#header-2 {
  font-size: 62px;
  line-height: 67px;
  color: #fff;
}
#button {
  display: inline-block;
  font-size: 18px;
  color: #3a3528;
  background-color: #fcc214;
  width: 334px;
  height: 81px;
  cursor: pointer;
  transition: all .3s;
  .button-text {
    line-height: 83px;
    padding-left: 33px;
    transition: all .3s;
    position: relative;
    right: 0;
    i {
      margin-right: 16px;
    }
  }
  &:hover {
    width: 353px;
    height: 91px;
    .button-text {
      line-height: 93px;
      right: -36px;
    }
    .button-arrow {
      right: 36px;
    }
  }
}

#button-mobile {
  position: relative;
  font-size: 13px;
  color: #3a3528;
  background-color: #fcc214;
  width: 220px;
  height: 55px;
  cursor: pointer;
  .button-text {
    position: relative;
    padding-left: 20px;
    line-height: 55px;
    i {
      margin-right: 16px;
    }
  }
}

.more {
  font-size: 20px;
  color: $main-color;
  padding: 5px;
  border-bottom: 2px solid $main-color;
  cursor: pointer;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}


/*********     CSS reset     *********/

html, body, div, span, h1, h2, h3, h4, h5, h6, p, a, img, i, ol, ul, li,
fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, figure, figcaption, footer, header, menu, nav, section{
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
  background-color: #fff;
}
ol, ul {
  list-style: none;
}

/*********     main styles     *********/

html, body {
  position: relative;
  font-family: $font-n-medium;
  font-size: 16px;
  min-width: 320px;
  margin: 0 auto;
  //max-width: 1920px;
  .header-wrapper {
    width: 94%;
    margin: 0 auto;
    //max-width: calc(1920px - 6%);
  }
  .wrapper {
    width: 90%;
    margin: 0 auto;
  }
}

/* hide scrollbar */
body::-webkit-scrollbar { width: 0; background: rgba(255, 255, 255, 0.0); }
body { -ms-overflow-style: none; }
//body { overflow: -moz-scrollbars-none; }

/* download */
.bg-download {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background: #000616;
  .wrapper {
    position: relative;
    .main-text {
      position: absolute;
      top: 185px;
      width: 750px;
      z-index: 2;
      &-header {
        font-size: 62px;
        line-height: 67px;
        color: #fff;
        z-index: 10001;
      }
      &-desc {
        font-family: $font-n-book;
        font-size: 28px;
        line-height: 33px;
        color: #fff;
        margin-top: 31px;
        margin-left: 9px;
      }
    }
  }
}

/* header */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 75px;
  background-color: #000512;
  margin: 0 auto;
  z-index: 1000;
  .logo {
    display: inline-block;
    margin-top: 19px;
    margin-right: 52px;
    z-index: 3;
  }
  .lang {
    position: relative;
    display: inline-block;
    color: $main-color;
    vertical-align: top;
    margin-top: 33px;
    padding-right: 105px;
    cursor: pointer;
    .arrow {
      @extend #arrow;
      top: 7px;
      left: 33px;
      width: 25px;
      background-color: $main-color;
      &:before, &:after {
        background-color: $main-color;
      }
    }
    &-hidden {
      display: none;
      position: absolute;
      top: -1px;
      left: 60px;
      font-size: 0;
      padding-left: 20px;
      span {
        position: relative;
        font-size: 16px;
        padding-left: 20px;
        padding-right: 20px;
        cursor: pointer;
        color: #fff;
        text-shadow: none;
        &:hover {
          color: $main-color;
          text-shadow: 5px 0px 20px $main-color,
          0px 5px 20px $main-color,
          -5px 0px 20px $main-color,
          0px -5px 20px $main-color;
        }
      }
    }
    &:hover {
      text-shadow: 5px 0px 20px $main-color,
      0px 5px 20px $main-color,
      -5px 0px 20px $main-color,
      0px -5px 20px $main-color;
      .arrow {
        left: 43px;
      }
    }
  }
  .nav {
    display: inline-block;
    vertical-align: top;
    margin-top: 32px;
    margin-right: 150px;
    a {
      position: relative;
      color: #fff;
      text-transform: uppercase;
      text-decoration: none;
      margin-right: 43px;
      &:hover {
        color: $main-color;
        &:after {
          content: '';
          width: 3px;
          height: 3px;
          background-color: $main-color;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -10px;
        }
      }
    }
  }
  .social-icons {
    display: inline-block;
    vertical-align: top;
    margin-top: 32px;
    margin-right: 150px;
    a {
      margin-right: 25px;
      color: #fff;
      position: relative;
      top: 0;
      transition: top .3s;
      &:hover {
        top: -5px;
      }
    }
  }
  .download {
    display: inline-block;
    vertical-align: top;
    margin-top: 23px;
    font-size: 18px;
    z-index: 3;
    span {
      color: $main-color;
      position: relative;
      top: -6px;
    }
    a {
      position: relative;
      top: 0;
      transition: top .3s;
      &:hover {
        top: -5px;
        &:before {
          @extend #aura-icon;
        }
      }
    }
    .android-icon {
      margin: 0 20px;
    }
  }
  .burger {
    display: none;
    .menu-checkbox {
      display: none;
      &:checked ~ .menu-button {
        z-index: 3;
      }
      &:checked ~ .menu-button span:nth-of-type(1) {
        transform: rotate(45deg) translate(0);
      }
      &:checked ~ .menu-button span:nth-of-type(2) {
        opacity: 0;
      }
      &:checked ~ .menu-button span:nth-of-type(3) {
        transform: rotate(-45deg) translate(0);
      }
      &:checked ~ nav {
        top: 0;
        display: block;
      }
    }
    .menu-button {
      position: relative;
      float: right;
      margin-top: 18px;
      width: 36px;
      cursor: pointer;
      &-wrapper {
        height: 20px;
        padding-top: 20px;
        span {
          position: absolute;
          width: 36px;
          height: 2px;
          background: $main-color;
          transition: 500ms;
          &:nth-of-type(1) {
            transform: translateY(-8px);
          }
          &:nth-of-type(3) {
            transform: translateY(8px);
          }
        }
      }
    }
    .nav-mobile {
      display: none;
      position: absolute;
      top: -100vh;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: #050e1a;
      transition: top 500ms;
      z-index: 2;
      overflow-y: auto;
      &-whitepaper {
        margin-top: 90px;
        font-size: 24px;
        color: $main-color;
        margin-right: 70px;
        cursor: pointer;
        a {
          text-decoration: none;
        }
        img {
          margin-right: 23px;
          transition: all .3s;
        }
        span {
          position: relative;
          top: -7px;
          right: 0;
          color: $main-color;
          transition: right .3s;
          &:after {
            content: "";
            width: 0;
            height: 1px;
            position: absolute;
            bottom: -10px;
            left: 0;
            background-color: $main-color;
            transition: width .3s;
          }
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
          span {
            right: -10px;
            &:after {
              width: 100%;
            }
          }
        }
      }
      &-video {
        margin-top: 20px;
        font-size: 24px;
        color: $main-color;
        cursor: pointer;
        img {
          margin-right: 23px;
          transition: all .3s;
        }
        span {
          position: relative;
          top: -7px;
          right: 0;
          transition: right .3s;
          &:after {
            content: "";
            width: 0;
            height: 1px;
            position: absolute;
            bottom: -10px;
            left: 0;
            background-color: $main-color;
            transition: width .3s;
          }
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
          span {
            right: -10px;
            &:after {
              width: 100%;
            }
          }
        }
      }
      ul {
        margin-top: 20px;
        li {
          margin-top: 15px;
          a {
            font-size: 28px;
            color: #fff;
            text-decoration: none;
          }
        }
      }
      &-social-icons {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
        max-width: 450px;
         a {
           position: relative;
           color: #fff;
           font-size: 26px;
           top: 0;
           transition: top .3s;
           &:hover {
             top: -5px;
           }
         }
      }
    }
  }
}

/* main screen */
.top {
  position: relative;
  top: -5000px;
}
.main-screen {
  position: relative;
  background: url(../img/main-screen-bg.jpg);
  background-size: cover;
  width: 100%;
  height: calc(100vh - 237px);
  overflow: hidden;
  position: fixed;
  //max-width: 1920px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  .bgvideo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    min-height: 100%;
  }
  .wrapper {
    position: relative;
    .main-text {
      position: absolute;
      top: 185px;
      width: 750px;
      z-index: 2;
      &-header {
        font-size: 62px;
        line-height: 67px;
        color: #fff;
        animation-delay: 1s;
        animation-duration: 1s;
      }
      &-desc {
        font-family: $font-n-book;
        font-size: 28px;
        line-height: 33px;
        color: #fff;
        margin-top: 31px;
        margin-left: 9px;
        animation-delay: 3s;
        animation-duration: 1s;
      }
    }
  }
  .main-why {
    position: absolute;
    top: 217px;
    right: 80px;
    font-size: 28px;
    line-height: 34px;
    width: 380px;
    cursor: pointer;
    &-1 {
      position: relative;
      padding-left: 66px;
      background: url(../img/main-diamond.png) no-repeat 0 50%;
      transition: all .3s;
      a {
        pointer-events: none;
        position: relative;
        color: #fff;
        text-decoration: none;
        display: block;
        transition: all .3s;
        &:after {
          content: "";
          width: 0;
          height: 2px;
          position: absolute;
          bottom: -14px;
          left: 0;
          background-color: $main-color;
          transition: width .3s;
        }
      }
      &:hover {
        margin-left: 10px;
        border-bottom-width: 100px;
        background: url(../img/main-diamond-hover.png) no-repeat 0 50%;
        a {
          color: $main-color;
          &:after {
            width: 90%;
          }
        }
        .arrow {
          background-color: $main-color;
          right: -40px;
          &:before, &:after {
            background-color: $main-color;
          }
        }
      }
    }
    &-2 {
      position: relative;
      padding-left: 66px;
      margin-top: 61px;
      background: url(../img/main-chart.png) no-repeat 0 50%;
      transition: all .3s;
      a {
        pointer-events: none;
        position: relative;
        color: #fff;
        text-decoration: none;
        display: block;
        transition: all .3s;
        &:after {
          content: "";
          width: 0;
          height: 2px;
          position: absolute;
          bottom: -14px;
          left: 0;
          background-color: $main-color;
          transition: width .3s;
        }
      }
      &:hover {
        margin-left: 10px;
        border-bottom-width: 100px;
        background: url(../img/main-chart-hover.png) no-repeat 0 50%;
        a {
          color: $main-color;
          &:after {
            width: 90%;
          }
        }
        .arrow {
          background-color: $main-color;
          right: -40px;
          &:before, &:after {
            background-color: $main-color;
          }
        }
      }
    }
    .arrow {
      @extend #arrow;
      float: right;
      top: 20px;
      right: -30px;
      transition: all .3s;
    }
  }
  .main-media {
    position: absolute;
    bottom: 40px;
    left: calc(5% + 20px);
    z-index: 2;
    &-whitepaper {
      display: inline-block;
      font-size: 24px;
      color: $main-color;
      margin-right: 70px;
      cursor: pointer;
      a {
        text-decoration: none;
        img {
          margin-right: 23px;
          transition: all .3s;
        }
        span {
          position: relative;
          top: -7px;
          right: 0;
          color: $main-color;
          transition: right .3s;
          &:after {
            content: "";
            width: 0;
            height: 1px;
            position: absolute;
            bottom: -10px;
            left: 0;
            background-color: $main-color;
            transition: width .3s;
          }
        }
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
        span {
          right: -10px;
          &:after {
            width: 100%;
          }
        }
      }
    }
    &-video {
      display: inline-block;
      cursor: pointer;
      font-size: 24px;
      color: $main-color;
      img {
        margin-right: 23px;
        transition: all .3s;
      }
      span {
        position: relative;
        top: -7px;
        right: 0;
        transition: right .3s;
        &:after {
          content: "";
          width: 0;
          height: 1px;
          position: absolute;
          bottom: -10px;
          left: 0;
          background-color: $main-color;
          transition: width .3s;
        }
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
        span {
          right: -10px;
          &:after {
            width: 100%;
          }
        }
      }
    }
  }
  .main-countdown {
    width: 700px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    &-top {
      font-size: 22px;
      color: #fff;
      text-transform: uppercase;
      .text-color-1 {
        font-size: 62px;
        color: #fcc213;
        text-transform: uppercase;
        padding-left: 15px;
      }
      .text-color-2 {
        font-size: 16px;
        color: #fcc213;
      }
      .text-color-3 {
        font-family: $font-n-demibold;
        font-size: 22px;
        color: #fcc213;
        text-shadow: 0 0 20px #fcc213;
        padding-left: 19px;
      }
    }
    &-counter {
      color: #fff;
      margin-top: 14px;
      margin-bottom: 50px;
      .counter-figure {
        position: relative;
        font-size: 119px;
      }
      .counter-colon {
        font-size: 119px;
        margin: 0 22px;
      }
      .counter-text {
        position: absolute;
        left: 9px;
        bottom: -7px;
        font-family: $font-n-light;
        font-size: 16px;
        text-transform: uppercase;
      }
    }
  }
}

/* countdown-mobile */
.countdown-mobile {
  display: none;
  position: relative;
  background: #6e7c7b;
  padding: 40px 0 40px;
  .wrapper {
    position: relative;
    .countdown-mobile-top {
      font-size: 18px;
      color: #fff;
      text-transform: uppercase;
      .text-color-1 {
        font-size: 24px;
        color: #fcc213;
        text-transform: uppercase;
        padding-left: 15px;
      }
      .text-color-2 {
        font-size: 18px;
        color: #fcc213;
      }
      .text-color-3 {
        font-family: $font-n-demibold;
        font-size: 18px;
        color: #fcc213;
        text-shadow: 0 0 20px #fcc213;
        position: absolute;
        top: -20px;
        left: 215px;
      }
    }
    .countdown-mobile-counter {
      color: #fff;
      margin-top: 14px;
      .counter-figure {
        position: relative;
        font-size: 60px;
      }
      .counter-colon {
        font-size: 50px;
        margin: 0 1px;
      }
      .counter-text {
        position: absolute;
        left: 0;
        bottom: -7px;
        font-family: $font-n-light;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
    .countdown-mobile-button {
      @extend #button-mobile;
      box-shadow: 0 0 70px -10px $main-color;
      float: right;
      margin-top: -60px;
      &-arrow {
        @extend #arrow;
        background: transparent;
        right: 20px;
        &:before, &:after {
          background-color: #3a3528;
        }
      }
    }
  }
}

/* what is */
@keyframes bar {
  from {
    width: 0;
    color: #fff;
  }
  to {
    //width: 152px;
    width: 10%;
    color: #000;
    box-shadow: 0 0 20px 2px $main-color;
  }
}
@keyframes bar2 {
  from {
    background-color: #17283a;
  }
  to {
    background-color: $main-color;
  }
}
.what-is {
  background: #697D7C url(../img/what-is-bg.jpg);
  background-size: cover;
  color: #fff;
  padding-bottom: 480px;
  margin-top: calc(100vh - 237px);
  position: relative;
  z-index: 1;
  &-total {
    display: inline-block;
    padding-top: 80px;
    margin-left: 5%;
    margin-right: 55px;
    .total-digit {
      font-family: $font-n-demibold;
      font-size: 46px;
      margin-bottom: 3px;
    }
    .total-dot {
      margin: 0 15px;
    }
    .total-text {
      font-size: 19px;
      text-transform: uppercase;
    }
  }
  &-bar {
    display: inline-block;
    vertical-align: top;
    margin-top: 68px;
    width: 536px;
    .bar-header {
      font-size: 22px;
      &-dot {
        margin: 0 7px;
      }
      &-text {
        font-size: 12px;
        text-transform: uppercase;
        padding-left: 8px;
      }
      &-finish {
        float: right;
      }
    }
    .bar-body {
      position: relative;
      width: 100%;
      height: 8px;
      background-color: #17283a;
      box-shadow: 0 7px 20px -1px rgba(0,0,0,.5);
      margin-top: 31px;
      &:before, &:after {
        content: '';
        width: 2px;
        height: 20px;
        background-color: #17283a;
        position: absolute;
        top: -20px;
        left: 0;
      }
      &:before {
        animation: bar2 0.2s ease 4.5s forwards;
      }
      &:after {
        left: auto;
        right: 0;
      }
      &-fill {
        position: absolute;
        width: 0;
        height: 14px;
        left: 0;
        bottom: -3px;
        background-color: $main-color;
        animation: bar 2.5s ease 4.5s forwards;
      }
    }
    .bar-footer {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      color: #b4bab9;
      margin-top: 20px;
      &-users {
        background: url(../img/bar-users-icon.png) no-repeat 0 2px;
        padding-left: 24px;
      }
      &-cap i {
        color: $main-color;
        padding-right: 3px;
      }
    }
  }
  &-mail {
    position: relative;
    top: 1px;
    width: 790px;
    float: right;
    .mail-text-info {
      display: inline-block;
      width: 265px;
      font-size: 22px;
      line-height: 26px;
      margin-top: 69px;
    }
    .mail-form {
      padding-top: 77px;
      width: 496px;
      height: 160px;
      float: right;
      background: $main-color;
      box-shadow: 0 0 50px 7px $main-color;
      transition: all .25s;
      form {
        position: relative;
      }
      &-email {
        //width: calc(100% - 165px);
        width: 326px;
        padding: 24px 10px 26px 85px;
        margin-left: 75px;
        background-color: transparent;
        background: url(../img/mail-icon.png) no-repeat 0 27px;
        font-size: 18px;
        color: #3a3528;
        border: none;
        outline: none;
        border-bottom: 1px solid #3a3528;
        transition: all .25s;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: #3a3528;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: #3a3528;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: #3a3528;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: #3a3528;
        }
        &:focus {
          &::-webkit-input-placeholder {
            opacity: .5;
          }
          &::-moz-placeholder {
            opacity: .5;
          }
          &:-ms-input-placeholder {
            opacity: .5;
          }
          &:-moz-placeholder {
            opacity: .5;
          }
        }
      }
      &-submit {
        position: absolute;
        top: 32px;
        right: 62px;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        transition: all .25s;
        &-inner {
          background-color: #3a3528;
          @extend #arrow;
          &:before, &:after {
            background-color: #3a3528;
          }
        }
      }
      &:hover {
        box-shadow: 0 0 60px 15px $main-color;
        .mail-form-email {
          padding-bottom: 8px;
          margin-left: 125px;
          padding-left: 35px;
        }
        .mail-form-submit {
          right: 48px;
        }
      }
    }
  }
  &-content {
    position: relative;
    margin-top: 367px;
    margin-left: 5%;
    min-height: 1300px;
    .what-is-mob-image {
      display: none;
    }
    h2 {
      opacity: 0;
      @extend #header-2;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      animation-delay: 0s;
      animation-duration: 1s;
    }
    p {
      opacity: 0;
      font-size: 43px;
      line-height: 59px;
      position: absolute;
      top: 100px;
      left: 0;
      z-index: 5;
      animation-delay: 1s;
      animation-duration: 1s;
    }
    .video-wrapper {
      width: 420px;
      opacity: 0;
      position: absolute;
      top: -180px;
      left: 1000px;
      z-index: 2;
      animation-delay: 1.5s;
      animation-duration: 1s;
      &:after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        right: 0;
        height: 6px;
        background-color: #6F7C7C;
      }
    }
    .what-is-video {
      width: 100%;
    }
    .what-is-store {
      position: absolute;
      top: 0;
      right: 0;
      width: 298px;
      z-index: 10;
      &-1, &-2 {
        height: 88px;
        overflow: hidden;
      }
      &-2 {
        margin-top: 15px;
      }
    }
    //.bg-img-1 {
    //  opacity: 0;
    //  position: absolute;
    //  top: -154px;
    //  right: 22px;
    //  z-index: 2;
    //  animation-delay: 2.5s;
    //  animation-duration: 1s;
    //}
    //.bg-img-2 {
    //  opacity: 0;
    //  position: absolute;
    //  top: -11px;
    //  right: 310px;
    //  z-index: 1;
    //  animation-delay: 2s;
    //  animation-duration: 1s;
    //}
    //.bg-img-3 {
    //  opacity: 0;
    //  position: absolute;
    //  top: 285px;
    //  right: 50px;
    //  animation-delay: 1.5s;
    //  animation-duration: 1s;
    //}
  }
  .wrapper {
    position: relative;
    opacity: 0;
    animation-delay: .5s;
    animation-duration: 1s;
    .what-is-footer {
      display: flex;
      justify-content: space-between;
      position: absolute;
      .item {
        position: relative;
        width: 20%;
        padding-top: 103px;
        .item-img {
          position: absolute;
          top: -27px;
          left: -27px;
        }
        &-title {
          font-size: 30px;
          color: #fcc214;
        }
        &-text {
          font-family: $font-n-light;
          font-size: 22px;
          line-height: 34px;
          margin-top: 42px;
        }
      }
    }
  }
}

/* about us */
.about-us {
  background-color: #000616;
  padding-top: 100px;
  padding-bottom: 100vh;
  position: relative;
  z-index: 1;
  h2 {
    @extend #header-2;
    margin-bottom: 120px;
    opacity: 0;
    animation-delay: 0s;
    animation-duration: 1s;
  }
  .about-items {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    opacity: 0;
    animation-delay: .5s;
    animation-duration: 1s;
    .about-item {
      position: relative;
      width: 216px;
      height: 216px;
      border-radius: 50%;
      margin-bottom: 30px;
      a {
        display: block;
        width:100%;
        height:100%;
      }
      &:hover {
        background-color: #0d1722;
      }
    }
    .about-item:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }
    .about-item-1:hover:before {
      background: url(../img/about-1.png) 0 100%;
    }
    .about-item-1:before {
      width: 157px;
      height: 26px;
      background: url(../img/about-1.png);
    }
    .about-item-2:hover:before {
      background: url(../img/about-2.png) 0 100%;
    }
    .about-item-2:before {
      width: 157px;
      height: 34px;
      background: url(../img/about-2.png);
    }
    .about-item-3:hover:before {
      background: url(../img/about-3.png) 0 100%;
    }
    .about-item-3:before {
      width: 157px;
      height: 32px;
      background: url(../img/about-3.png);
    }
    .about-item-4:hover:before {
      background: url(../img/about-4.png) 0 100%;
    }
    .about-item-4:before {
      width: 157px;
      height: 51px;
      background: url(../img/about-4.png);
    }
    .about-item-5:hover:before {
      background: url(../img/about-5.png) 0 100%;
    }
    .about-item-5:before {
      width: 156px;
      height: 26px;
      background: url(../img/about-5.png);
    }
    .about-item-6:hover:before {
      background: url(../img/about-6.png) 0 49px;
    }
    .about-item-6:before {
      width: 172px;
      height: 48px;
      background: url(../img/about-6.png);
    }
    .about-item-7:hover:before {
      background: url(../img/about-7.png) 0 100%;
    }
    .about-item-7:before {
      width: 174px;
      height: 31px;
      background: url(../img/about-7.png);
    }
    .about-item-8:hover:before {
      background: url(../img/about-8.png) 0 100%;
    }
    .about-item-8:before {
      width: 174px;
      height: 20px;
      background: url(../img/about-8.png);
    }
    .about-item-9:hover:before {
      background: url(../img/about-9.png) 0 100%;
    }
    .about-item-9:before {
      width: 174px;
      height: 29px;
      background: url(../img/about-9.png);
    }
    .about-item-10:hover:before {
      background: url(../img/about-10.png) 0 100%;
    }
    .about-item-10:before {
      width: 174px;
      height: 13px;
      background: url(../img/about-10.png);
    }
  }
  #about-us-items-2-anim {
    animation-delay: 1s;
  }
}

/* multi-sig */
.multi-sig {
  position: relative;
  color: #fff;
  background: #697D7C url(../img/sig-bg.jpg) left bottom fixed;
  background-size: cover;
  margin-top: -150vh;
  min-height: 3250px;
  .wrapper {
    position: relative;
    .sig-icons {
      font-family: $font-n-book;
      font-size: 22px;
      position: fixed;
      top: 0;
      left: 8%;
      display: none;
      .sig-icon {
        padding-top: 70px;
        text-align: center;
        margin-top: 120px;
      }
      .sig-icon-1 {
        background: url(../img/sig-icon-1.png) no-repeat 50% 0;
      }
      .sig-icon-2 {
        background: url(../img/sig-icon-2.png) no-repeat 50% 0;
      }
      .sig-icon-3 {
        background: url(../img/sig-icon-3.png) no-repeat 50% 0;
      }
    }
    .sig-content {
      position: absolute;
      width: 55%;
      height: 100vh;
      top: 1000px;
      right: 64px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 1;
      .sig-mob-img-1 {
        display: none;
      }
      h2 {
        font-size: 59px;
        line-height: 78px;
        opacity: 0;
        animation-delay: .5s;
        animation-duration: 1s;
      }
      p {
        font-family: $font-n-book;
        font-size: 22px;
        line-height: 42px;
        margin-top: 90px;
        opacity: 0;
        animation-delay: 1s;
        animation-duration: 1s;
      }
    }
    .wallet-content {
      position: absolute;
      width:45%;
      height: 100vh;
      top: calc(1000px + 100vh);
      right: 245px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 1;
      .sig-mob-img-2 {
        display: none;
      }
      h2 {
        @extend #header-2;
        opacity: 0;
        animation-delay: .5s;
        animation-duration: 1s;
      }
      p {
        font-family: $font-n-book;
        font-size: 22px;
        line-height: 42px;
        margin-top: 80px;
        opacity: 0;
        animation-delay: 1s;
        animation-duration: 1s;
      }
    }
    .sig-img-1 {
      position: fixed;
      top: 100px;
      left: 15%;
      opacity: 0;
    }
    .wallet-img-1 {
      position: fixed;
      top: 100px;
      left: 15%;
      opacity: 0;
    }
    &:after {
      content: "";
      display: table;
      clear: both;

    }
  }
  &-store {
    position: fixed;
    bottom: 0;
    background-color: #000;
    transition: height .2s;
  }
  &-store-1 {
    right: 302px;
    height: 88px;
    img {
      display: block;
    }
    &:hover {
      height: 98px;
    }
  }
  &-store-2 {
    right: 0;
    height: 88px;
    img {
      display: block;
    }
    &:hover {
      height: 98px;
    }
  }
}

/* wallet2 */
.wallet2 {
  position: relative;
  color: #fff;
  background-color: #000616;
  min-height: 700px;
  //overflow: hidden;
  z-index: 1;
  .wrapper {
    .wallet2-advs1, .wallet2-advs2 {
      display: flex;
      justify-content: space-between;
      opacity: 0;
      .adv {
        position: relative;
        flex-basis: 430px;
        padding-left: 80px;
        margin: 150px 10px 0 10px;
        &-image {
          position: absolute;
          top: -5px;
          left: 0;
        }
        &-title {
          font-size: 28px;
          color: #fcc214;
        }
        &-text {
          font-family: $font-n-light;
          font-size: 22px;
          line-height: 34px;
          color: #fff;
          margin-top: 40px;
        }
      }
    }
    &-advs1 {
      top: -100px;
    }
    &-advs2 {
      top: 900px;
    }
  }
}

.era-outer-title {
  position: relative;
  height: 50vh;
  z-index: 2;
  color: #fff;
  background: transparent;
  .wrapper {
    position: relative;
    .era-title {
      position: absolute;
      top: 250px;
      left: 0;
      width: 660px;
      @extend #header-2;
      opacity: 0;
      animation-delay: 1s;
      animation-duration: 1s;
    }
  }
}

/* era */
.era-wrapper {
  position: relative;
  background: #000616;
  margin-top: -50vh;
  overflow: hidden;
  .era {
    position: relative;
    background: #000 url(../img/era-bg.jpg) no-repeat -670px -350px;
    height: 2050px;
    color: #fff;
    opacity: 0;
    .era-mob-img {
      display: none;
    }
    .wrapper {
      position: relative;
      .era-title {
        display: none;
      }
      .era-text {
        position: absolute;
        top: 600px;
        right: 0;
        width: 680px;
        font-family: $font-n-book;
        font-size: 22px;
        line-height: 42px;
        opacity: 0;
        animation-delay: 0s;
        animation-duration: 1s;
      }
    }
    &-building-1 {
      position: absolute;
      bottom: 0;
      left: 0;
      //opacity: 0;
      transform: translate3d(-150px, 300px, 0);
    }
    &-building-2 {
      position: absolute;
      bottom: 0;
      right: 0;
      //opacity: 0;
      transform: translate3d(150px, 300px, 0);
    }
    &-bottom-content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 660px;
      width: 640px;
      &-title {
        @extend #header-2;
        position: absolute;
        top: 0;
        text-indent: -96px;
        line-height: 67px;
        opacity: 0;
        &.header-anim {
          animation-delay: .5s;
          animation-duration: 1s;
        }
      }
      &-text {
        position: absolute;
        top: 200px;
        font-size: 22px;
        line-height: 42px;
        margin-top: 63px;
        opacity: 0;
        &.sub-header-anim-3 {
          animation-delay: 1s;
          animation-duration: 1s;
        }
      }
    }
    .era-building-1-mob, .era-building-2-mob {
      display: none;
    }
  }
}

/* advantages-1 */
.advantages-1 {
  background-color: #08111d;
  color: #fff;
  padding: 100px 0 100px;
  position: relative;
  .wrapper {
    display: flex;
    justify-content: space-between;
    .advantage {
      position: relative;
      width: 25%;
      padding-left: 90px;
      &-image {
        position: absolute;
        top: 5px;
        left: 0;
      }
      &-title {
        font-size: 28px;
        color: #fcc214;
        width: 80%;
      }
      &-text {
        font-family: $font-n-book;
        font-size: 22px;
        line-height: 34px;
        margin-top: 50px;
      }
    }
  }
}

/* chart */
.chart {
  position: relative;
  background-color: #08111d;
  color: #fff;
  padding: 100px 0 200px;
  height: 2036px;
  &-mob-image {
    display: none;
  }
  .wrapper {
    position: relative;
    z-index: 2;
    .chart-header-text {
      width: 55%;
      font-size: 43px;
      line-height: 59px;
      position: absolute;
      top: 126px;
      left: 0;
      opacity: 0;
      animation-delay: 0s;
      animation-duration: 1s;
    }
    .chart-header-button {
      @extend #button;
      position: absolute;
      top: 239px;
      right: 12px;
      box-shadow: 0 0 70px -10px $main-color;
      opacity: 0;
      animation-delay: 2s;
      animation-duration: 1s;
      &-text {
        position: relative;
        right: 0;
        transition: right .2s;
      }
      &-arrow {
        @extend #arrow;
        background: transparent;
        transition: right .2s;
        &:before, &:after {
          background-color: #3a3528;
        }
      }
      &:hover {
        box-shadow: 0 0 70px 0px $main-color;
      }
    }
    .chart-cube-mob {
      display: none;
    }
    .chart-body-title {
      font-size: 62px;
      line-height: 67px;
      width: 800px;
      position: absolute;
      top: 1247px;
      left: 0;
      z-index: 2;
      opacity: 0;
      animation-delay: 0s;
      animation-duration: 1s;
    }
    .chart-body-text1 {
      font-family: $font-n-book;
      font-size: 22px;
      line-height: 42px;
      width: 680px;
      position: absolute;
      top: 1641px;
      left: 0;
      z-index: 2;
      opacity: 0;
      animation-delay: 1s;
      animation-duration: 1s;
    }
    .chart-body-text2 {
      font-family: $font-n-book;
      font-size: 22px;
      line-height: 42px;
      width: 658px;
      position: absolute;
      top: 1641px;
      right: 0;
      z-index: 2;
      opacity: 0;
      animation-delay: 2s;
      animation-duration: 1s;
    }
    .chart-body-cube {
      position: absolute;
      &.cube-1 {
        top: 1350px;
        left: 50%;
        transform: translateX(-50%);
      }
      &.cube-2 {
        top: 1660px;
        left: 50%;
        transform: translateX(-50%);
      }
      &.cube-3 {
        top: 1870px;
        top: 1870px;
        left: 50%;
        transform: translateX(100%);
      }
      &.cube-4 {
        top: 1910px;
        left: 50%;
        transform: translateX(-82%);
      }
    }
  }
  &-image {
    position: absolute;
    top: 566px;
    left: 0;
    right: 0;
    &.sub-header-anim-2 {
      opacity: 0;
      animation-delay: 1s;
      animation-duration: 1s;
    }
    img {
      width: 100%;
    }
  }
}

/* advantages-2 */
.advantages-2 {
  background-color: #6e7c7b;
  position: relative;
  .wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 100px 0 150px;
    opacity: 0;
    .item {
      color: #fff;
      position: relative;
      width: 25%;
      padding-top: 103px;
      .item-img {
        position: absolute;
        top: -27px;
        left: -27px;
      }
      &-title {
        font-size: 30px;
        //color: #fcc214;
      }
      &-text {
        font-family: $font-n-book;
        font-size: 22px;
        line-height: 34px;
        margin-top: 71px;
      }
    }
    .advantages-2-video {
      position: absolute;
      right: 420px;
      bottom: 18px;
      font-size: 18px;
      color: #fff;
      cursor: pointer;
      img {
        margin-right: 23px;
        width: 43px;
        height: 43px;
        transition: all .3s;
      }
      span {
        position: relative;
        top: -15px;
        right: 0;
        transition: all .3s;
        &:after {
          content: "";
          width: 0;
          height: 1px;
          position: absolute;
          bottom: -10px;
          left: 0;
          background-color: $main-color;
          transition: width .3s;
        }
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
        span {
          color: $main-color;
          right: -10px;
          &:after {
            width: 100%;
          }
        }
      }
    }
    .advantages-2-button {
      @extend #button;
      position: absolute;
      right: 0;
      bottom: 0;
      box-shadow: 0 0 70px -7px #000;
      &-text {
        position: relative;
        top: -2px;
        right: -10px;
      }
      &-key {
        margin-right: 5px;
      }
      &-arrow {
        @extend #arrow;
        position: absolute;
        top: 52%;
        transform: translateY(-50%);
        right: 50px;
        background-color: transparent;
        &:before, &:after {
          background-color: #3a3528;
        }
      }
      &:hover {
        box-shadow: 0 0 70px 0px $main-color;
      }
    }
  }
}

/* rocket */
.rocket {
  background: #000512 url(../img/rocket.png) no-repeat 100% 0px;
  position: relative;
  .wrapper {
    position: relative;
    height: 1300px;
    .rocket-title {
      @extend #header-2;
      line-height: 67px;
      width: 770px;
      position: absolute;
      top: 160px;
      left: 0;
      opacity: 0;
      animation-delay: 0s;
      animation-duration: 1s;
    }
    .rocket-text1 {
      position: absolute;
      font-family: $font-n-book;
      font-size: 22px;
      line-height: 42px;
      color: #fff;
      width: 600px;
      top: 510px;
      left: 0;
      opacity: 0;
      animation-delay: 1s;
      animation-duration: 1s;
    }
  }
}

/* advantages-3 */
.advantages-3 {
  background-color: #000512;
  color: #fff;
  padding: 0 0 200px;
  position: relative;
  .wrapper {
    display: flex;
    justify-content: space-between;
    opacity: 0;
    .advantage {
      position: relative;
      width: 26%;
      padding-left: 90px;
      &-image {
        position: absolute;
        top: 5px;
        left: 0;
      }
      &-title {
        font-size: 28px;
        color: #fcc214;
        width: 80%;
      }
      &-text {
        font-family: $font-n-book;
        font-size: 22px;
        line-height: 34px;
        margin-top: 50px;
      }
    }
  }
}

/* p2p */
.p2p {
  position: relative;
  background-color: #000512;
  color: #fff;
  height: 1036px;
  //overflow: hidden;
  .wrapper {
    position: relative;
    .p2p-image-mob {
      display: none;
    }
    .p2p-title {
      font-size: 59px;
      line-height: 78px;
      position: absolute;
      top: 200px;
      left: 0;
      z-index: 2;
      opacity: 0;
      animation-delay: 1s;
      animation-duration: 1s;
    }
    .p2p-text {
      font-family: $font-n-book;
      font-size: 22px;
      line-height: 42px;
      margin-top: 71px;
      width: 600px;
      position: absolute;
      top: 450px;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 2;
      opacity: 0;
      animation-delay: 1.5s;
      animation-duration: 1s;
    }
  }
  .p2p-image {
    position: absolute;
    &.image-1 {
      top: -100px;
      right: 152px;
      opacity: 0;
      z-index: 1;
      animation-delay: .5s;
      animation-duration: 1s;
    }
    &.image-2 {
      top: 462px;
      left: -152px;
      opacity: 0;
      z-index: 1;
      animation-delay: 2s;
      animation-duration: 1s;
    }
    &.image-3 {
      top: 796px;
      right: 300px;
      opacity: 0;
      z-index: 1;
      animation-delay: 2.5s;
      animation-duration: 1s;
    }
  }
  .p2p-video {
    position: absolute;
    right: 420px;
    bottom: 94px;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    img {
      margin-right: 23px;
      width: 43px;
      height: 43px;
      transition: all .3s;
    }
    span {
      position: relative;
      top: -15px;
      right: 0;
      transition: all .3s;
      &:after {
        content: "";
        width: 0;
        height: 1px;
        position: absolute;
        bottom: -10px;
        left: 0;
        background-color: $main-color;
        transition: width .3s;
      }
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
      span {
        color: $main-color;
        right: -10px;
        &:after {
          width: 100%;
        }
      }
    }
  }
  .p2p-button {
    @extend #button;
    position: absolute;
    right: 0;
    bottom: 76px;
    box-shadow: 0 0 70px -7px #000;
    &-arrow {
      @extend #arrow;
      position: absolute;
      top: 52%;
      transform: translateY(-50%);
      right: 50px;
      background-color: transparent;
      &:before, &:after {
        background-color: #3a3528;
      }
    }
    &:hover {
      box-shadow: 0 0 70px 0px $main-color;
    }
  }
}

/* dex */
.dex {
  position: relative;
  background-color: #000512;
  color: #fff;
  height: 1500px;
  overflow: hidden;
  &-image {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  &-image-mob {
    display: none;
  }
  .wrapper {
    position: relative;
    .dex-title {
      font-size: 62px;
      line-height: 67px;
      position: absolute;
      top: 268px;
      left: 0;
      z-index: 2;
      opacity: 0;
      animation-delay: .2s;
      animation-duration: 1s;
    }
    .dex-title-desc {
      font-family: $font-n-book;
      font-size: 19px;
      line-height: 67px;
      position: absolute;
      top: 343px;
      left: 117px;
      z-index: 2;
      opacity: 0;
      animation-delay: .4s;
      animation-duration: 1s;
    }
    .dex-text-1 {
      font-size: 43px;
      line-height: 59px;
      position: absolute;
      top: 568px;
      left: 0;
      width: 950px;
      z-index: 2;
      opacity: 0;
      animation-delay: 2s;
      animation-duration: 1s;
    }
    .dex-text-2 {
      font-size: 43px;
      line-height: 59px;
      position: absolute;
      top: 945px;
      left: 0;
      width: 635px;
      z-index: 2;
      opacity: 0;
      animation-delay: 3s;
      animation-duration: 1s;
    }
  }
}

/* dex-adv */
.dex-advs {
  background-color: #6e7c7b;
  color: #fff;
  padding: 100px 0 100px;
  position: relative;
  .wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    .item {
      position: relative;
      width: 25%;
      padding-top: 103px;
      .item-img {
        position: absolute;
        top: -27px;
        left: -27px;
      }
      &-title {
        font-size: 30px;
      }
      &-text {
        font-family: $font-n-book;
        font-size: 22px;
        line-height: 34px;
        margin-top: 71px;
      }
    }
    &.wrapper-2 {
      margin-top: 150px;
    }
    &.wrapper-3 {
      display: block;
    }
  }
  &-title {
    font-size: 43px;
    line-height: 59px;
    width: 980px;
    margin-top: 200px;
    &.sub-header-anim-2 {
      opacity: 0;
      animation-delay: .5s;
      animation-duration: 1s;
    }
  }
  &-button {
    @extend #button;
    position: absolute;
    top: 55px;
    right: 0;
    box-shadow: 0 0 70px -7px #000;
    &.sub-header-anim-2 {
      opacity: 0;
      animation-delay: 1s;
      animation-duration: 1s;
    }
    &-text {
      position: relative;
      right: 0;
      transition: right .2s;
    }
    &-arrow {
      @extend #arrow;
      //background-color: #3a3528;
      background: transparent;
      transition: right .2s;
      &:before, &:after {
        background-color: #3a3528;
      }
    }
    &:hover {
      box-shadow: 0 0 70px 0px $main-color;
    }
  }
}

/* dex-video */
.dex-video {
  background-color: #050e1a;
  color: #fff;
  padding-top: 157px;
  position: relative;
  .wrapper {
    position: relative;
    height: 847px;
    .dex-video-title {
      position: absolute;
      top: 124px;
      font-size: 62px;
      line-height: 67px;
      width: 680px;
      user-select: none;
      z-index: 10;
      opacity: 0;
      animation-delay: .5s;
      animation-duration: 1s;
    }
    .dex-video-text {
      position: absolute;
      top: 253px;
      font-family: $font-n-light;
      font-size: 22px;
      line-height: 34px;
      margin-top: 71px;
      width: 440px;
      z-index: 10;
      opacity: 0;
      animation-delay: 1s;
      animation-duration: 1s;
    }
    .dex-video-channel {
      position: absolute;
      left: 0;
      bottom: 72px;
      a {
        color: #fff;
        text-decoration: none;
        font-size: 24px;
        img {
          transition: all .3s;
        }
        span {
          position: relative;
          top: -33px;
          right: 0;
          transition: all .3s;
          &:after {
            content: "";
            width: 0;
            height: 1px;
            position: absolute;
            bottom: -10px;
            left: 0;
            background-color: $main-color;
            transition: width .3s;
          }
        }
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
        span {
          right: -10px;
          color: $main-color;
          &:after {
            width: 100%;
          }
        }
      }
    }
    .player-wrapper {
      width: 1170px;
      float: right;
      box-shadow: 0 20px 50px 0px #000;
      .owl-carousel .item-video {
        height: 720px;
      }
      .owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next {
        position: absolute;
        width: 100px;
        height: 100px;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        background-color: rgba(255,255,255,.05);
        transition: background-color .2s;
        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: rgba(255,255,255,.5);
        }
        &:hover {
          background-color: rgba(255,255,255,.1);
        }
      }
      .owl-carousel .owl-nav .owl-prev {
        left: 0;
      }
      .owl-carousel .owl-nav .owl-prev:before {
        content: '';
        width: 30px;
        height: 1px;
        background: #fff;
        position: absolute;
        top: 39px;
        left: 50%;
        transform: translateX(-50%) rotate(-45deg);
      }
      .owl-carousel .owl-nav .owl-prev:after {
        content: '';
        width: 30px;
        height: 1px;
        background: #fff;
        position: absolute;
        bottom: 39px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }
      .owl-carousel .owl-nav .owl-next {
        right: 0;
      }
      .owl-carousel .owl-nav .owl-next:before {
        content: '';
        width: 30px;
        height: 1px;
        background: #fff;
        position: absolute;
        top: 39px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }
      .owl-carousel .owl-nav .owl-next:after {
        content: '';
        width: 30px;
        height: 1px;
        background: #fff;
        position: absolute;
        bottom: 39px;
        left: 50%;
        transform: translateX(-50%) rotate(-45deg);
      }
      .owl-carousel .owl-dots {
        position: absolute;
        bottom: 108px;
        left: -552px;
        .owl-dot {
          display: inline-block;
          width: 64px;
          height: 5px;
          background: #2f3741;
          margin-right: 28px;
          &.active {
            background: #fff;
          }
        }
      }
      .owl-carousel .owl-video-play-icon {
        background: url(../img/owl.video.play.png) no-repeat;
      }
    }
  }
}

/* price */
.price {
  background-color: #050e1a;
  color: #fff;
  position: relative;
  .wrapper {
    position: relative;
    .price-title {
      position: absolute;
      top: 160px;
      left: 0;
      font-size: 48px;
      line-height: 63px;
      pointer-events: none;
      z-index: 2;
      opacity: 0;
      animation-delay: .5s;
      animation-duration: 1s;
    }
    .price-cost {
      position: absolute;
      top: 306px;
      left: 0;
      font-size: 13px;
      pointer-events: none;
      z-index: 2;
      opacity: 0;
      animation-delay: 1s;
      animation-duration: 1s;
      &-1 {
        font-size: 22px;
        text-transform: uppercase;
        margin-right: 10px;
      }
      &-2 {
        font-size: 62px;
      }
      &-3 {
        font-size: 16px;
        text-transform: uppercase;
        margin-right: 20px;
      }
      &-4 {
        font-size: 32px;
        margin-right: 5px;
      }
    }
    .price-text {
      font-family: $font-n-book;
      font-size: 22px;
      line-height: 42px;
      width: 540px;
      text-align: right;
      position: absolute;
      top: 173px;
      right: 0;
      pointer-events: none;
      z-index: 2;
      opacity: 0;
      animation-delay: 2s;
      animation-duration: 1s;
    }
  }
  .price-items {
    display: flex;
    justify-content: space-between;
    .price-item {
      flex-grow: 1;
      text-align: center;
      padding: 450px 10px 185px;
      color: #074427;
      border-right: 1px solid #18212c;
      transition: all .3s;
      &.disabled {
        color: #232b36
      }
      &.active {
        color: #fff;
      }
      &-stage {
        font-size: 80px;
        transition: margin-top .2s;
      }
      &-desc {
        font-family: $font-n-book;
        font-size: 22px;
        text-transform: uppercase;
        margin-top: 20px;
      }
      &-xlm {
        font-size: 43px;
        margin-top: 74px;
        span {
          font-size: 19px;
        }
      }
      &-discount {
        font-family: $font-n-demibold;
        font-size: 19px;
        text-transform: uppercase;
        margin-top: 5px;
      }
      &-total {
        font-family: $font-n-book;
        font-size: 13px;
        text-transform: uppercase;
        margin-top: 90px;
      }
      &-total-digits {
        font-family: $font-n-demibold;
        font-size: 28px;
        margin-top: 7px;
      }
      &-remains {
        font-family: $font-n-book;
        font-size: 13px;
        text-transform: uppercase;
        margin-top: 40px;
      }
      &-remains-digits {
        font-family: $font-n-demibold;
        font-size: 28px;
        margin-top: 7px;
      }
      .dot {
        margin: 0 8px;
      }
      &.item-1:hover, &.item-2:hover {
        background: #0d1722;
        color: #676f7a;
        padding-left: 50px;
        padding-right: 50px;
      }
      &.item-3 {
        position: relative;
        background: #6e7c7b;
        min-width: 200px;
        .price-item-discount {
          position: absolute;
          top: 57%;
          right: -10px;
          font-size: 21px;
          background: #fcc214;
          color: #3a3528;
          padding: 10px 10px;
          box-shadow: 0 0 50px 0px #fcc313;
          transition: all .2s;
        }
        &:hover {
          padding-left: 50px;
          padding-right: 50px;
          .price-item-discount {
            padding: 10px 25px;
          }
          .price-item-button {
            height: 91px;
            .button-text {
              line-height: 93px;
            }
          }
        }
      }
      &.item-4:hover, &.item-5:hover, &.item-6:hover {
        background: #0d1722;
        color: #00d36f;
        padding-left: 50px;
        padding-right: 50px;
      }
      &-button {
        @extend #button;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: 0 0 50px 0px #fcc313;
        font-size: 18px;
        z-index: 5;
        width: 100%;
        text-align: left;
        transition: all .2s;
        &-text {
          position: relative;
          right: 0;
          transition: right .2s;
        }
        &-arrow {
          @extend #arrow;
          background-color: transparent;
          transition: right .2s;
          &:before, &:after {
            background-color: #3a3528;
          }
        }
        &:hover {
          width: 100%;
          height: 91px;
          box-shadow: 0 0 60px 5px #fcc313;
        }
      }
    }
    .price-mob-button {
      display: none;
      @extend #button-mobile;
      margin: 30px auto 0;
      box-shadow: 0 0 70px -10px $main-color;
      &-arrow {
        @extend #arrow;
        background: transparent;
        right: 20px;
        &:before, &:after {
          background-color: #3a3528;
        }
      }
    }
  }
}

/* ideology */
.ideology {
  position: relative;
  color: #fff;
  overflow: hidden;
  &-title {
    position: absolute;
    top: 243px;
    left: 420px;
    width: 450px;
    font-size: 62px;
    line-height: 67px;
    pointer-events: none;
    &.header-anim {
      opacity: 0;
      animation-delay: .5s;
      animation-duration: 1s;
    }
  }
  &-text {
    position: absolute;
    top: 227px;
    right: 405px;
    width: 570px;
    font-family: $font-n-book;
    font-size: 22px;
    line-height: 42px;
    pointer-events: none;
    &.sub-header-anim-3 {
      opacity: 0;
      animation-delay: 1.5s;
      animation-duration: 1s;
    }
  }
  &-items {
    display: flex;
    justify-content: space-between;
    background-color: #050e1a;
    min-height: 1152px;
    .ideology-item {
      width: 33.33%;
      padding-top: 603px;
      padding-left: 70px;
      background-color: #050e1a;
      border-right: 1px solid #18212c;
      transition: all .3s;
      &:last-child {
        border: none;
      }
      &:hover {
        background-color: #0d1722;
        padding-top: 465px;
        .ideology-item-wrapper .ideology-item-text {
          opacity: 1;
        }
      }
      &-wrapper {
        position: relative;
        padding: 0 60px 60px 90px;
        .ideology-image {
          position: absolute;
          top: 5px;
          left: 0;
        }
        .ideology-item-title {
          font-size: 28px;
          line-height: 33px;
          color: #fcc214;
          word-wrap: break-word;
        }
        .ideology-item-text {
          font-family: $font-n-book;
          font-size: 22px;
          line-height: 34px;
          margin-top: 50px;
          opacity: 0;
          transition: all 1.5s;
        }
      }
    }
  }
}

/* usefulness */
.usefulness {
  height: 520px;
  background-color: #7b8887;
  color: #fff;
  overflow: hidden;
  position: relative;
  .wrapper {
    position: relative;
    .usefulness-title {
      position: absolute;
      top: 95px;
      left: 0;
      font-size: 62px;
      line-height: 67px;
      width: 690px;
      opacity: 0;
      animation-delay: .5s;
      animation-duration: 1s;
    }
    .usefulness-text-1 {
      position: absolute;
      top: 300px;
      left: 0;
      font-family: $font-n-book;
      font-size: 22px;
      line-height: 42px;
      width: 880px;
      opacity: 0;
      animation-delay: 1.5s;
      animation-duration: 1s;
    }
    .usefulness-text-2 {
      position: absolute;
      top: 300px;
      right: 85px;
      font-family: $font-n-book;
      font-size: 22px;
      line-height: 42px;
      width: 570px;
      opacity: 0;
      animation-delay: 2s;
      animation-duration: 1s;
    }
  }
}

/* steps-1 */
.steps-1 {
  color: #fff;
  position: relative;
  .outer-wrapper-1 {
    padding: 100px 0 100px;
    background-color: #7b8887;
  }
  .outer-wrapper-2 {
    padding: 100px 0 100px;
    background-color: #6e7c7b;
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    .item {
      width: 25%;
      &-title {
        font-size: 62px;
      }
      &-text {
        font-family: $font-n-book;
        font-size: 22px;
        line-height: 42px;
        margin-top: 77px;
      }
    }
  }
}

/* distribution */
.distribution {
  color: #fff;
  background-color: #050e1a;
  height: 1500px;
  position: relative;
  .wrapper {
    position: relative;
    padding: 600px 0 200px;
    .distribution-title {
      font-size: 62px;
      line-height: 67px;
      position: absolute;
      top: 100px;
      left: 0;
      opacity: 0;
      animation-delay: .5s;
      animation-duration: 1s;
    }
    .distribution-text {
      font-size: 43px;
      line-height: 59px;
      width: 870px;
      position: absolute;
      top: 234px;
      left: 0;
      opacity: 0;
      animation-delay: 1.5s;
      animation-duration: 1s;
    }
    .bar-1 {
      display: flex;
      justify-content: space-between;
      padding-top: 133px;
      position: relative;
      &-title {
        position: absolute;
        top: 10px;
        left: 0;
        font-size: 28px;
      }
      &-item {
        height: 14px;
        background-color: #8a9b9a;
        position: relative;
        box-shadow: inset 5px 0 30px -3px #000;
        &.item-1 {
          width: 40%;
          .item-1-fill {
            position: absolute;
            width: 100%;
            height: 14px;
            left: 0;
            bottom: 0;
            background-color: #fcc313;
            box-shadow: 0 0 20px 2px $main-color;
          }
        }
        &.item-2 {
          width: 17%;
          .item-2-fill {
            position: absolute;
            width: 0%;
            height: 14px;
            left: 0;
            bottom: 0;
            background-color: #fcc313;
            //box-shadow: 0 0 20px 2px $main-color;
          }
        }
        &.item-3 {
          width: 16%;
          .item-3-fill {
            position: absolute;
            width: 0%;
            height: 14px;
            left: 0;
            bottom: 0;
            background-color: #fcc313;
            //box-shadow: 0 0 20px 2px $main-color;
          }
        }
        &.item-4 {
          width: 10%;
          .item-4-fill {
            position: absolute;
            width: 0%;
            height: 14px;
            left: 0;
            bottom: 0;
            background-color: #fcc313;
            //box-shadow: 0 0 20px 2px $main-color;
          }
        }
        &.item-5 {
          width: 10%;
          .item-5-fill {
            position: absolute;
            width: 0%;
            height: 14px;
            left: 0;
            bottom: 0;
            background-color: #fcc313;
            //box-shadow: 0 0 20px 2px $main-color;
          }
        }
        &.item-6 {
          width: 15%;
          .item-6-fill {
            position: absolute;
            width: 0%;
            height: 14px;
            left: 0;
            bottom: 0;
            background-color: #fcc313;
            //box-shadow: 0 0 20px 2px $main-color;
          }
        }
        &.item-7 {
          width: 9%;
          .item-7-fill {
            position: absolute;
            width: 0%;
            height: 14px;
            left: 0;
            bottom: 0;
            background-color: #fcc313;
            //box-shadow: 0 0 20px 2px $main-color;
          }
        }
        &.item-8 {
          width: 8%;
          .item-8-fill {
            position: absolute;
            width: 0%;
            height: 14px;
            left: 0;
            bottom: 0;
            background-color: #fcc313;
            //box-shadow: 0 0 20px 2px $main-color;
          }
        }
        .item-percentage {
          font-size: 19px;
          line-height: 10px;
          text-align: right;
          position: absolute;
          top: -128px;
          right: 0;
        }
        .item-desc {
          font-family: $font-n-light;
          font-size: 14px;
          line-height: 17px;
          text-align: right;
          position: absolute;
          top: -108px;
          right: 0;
        }
        &:after {
          content: '';
          width: 2px;
          height: 28px;
          background-color: #354047;
          position: absolute;
          top: -52px;
          right: 0;
        }
      }
    }
    .bar-2 {
      display: flex;
      justify-content: space-between;
      padding-top: 133px;
      position: relative;
      margin-top: 190px;
      &-title {
        position: absolute;
        top: 10px;
        left: 0;
        font-size: 28px;
      }
      &-item {
        height: 14px;
        background-color: #8a9b9a;
        position: relative;
        box-shadow: inset 5px 0 30px -3px #000;
        &.item-1 {
          width: 50%;
          .item-1-fill {
            position: absolute;
            width: 100%;
            height: 14px;
            left: 0;
            bottom: 0;
            background-color: #fcc313;
            box-shadow: 0 0 20px 2px $main-color;
          }
        }
        &.item-2 {
          width: 10%;
          .item-2-fill {
            position: absolute;
            width: 0%;
            height: 14px;
            left: 0;
            bottom: 0;
            background-color: #fcc313;
            //box-shadow: 0 0 20px 2px $main-color;
          }
        }
        &.item-3 {
          width: 5%;
          .item-3-fill {
            position: absolute;
            width: 0%;
            height: 14px;
            left: 0;
            bottom: 0;
            background-color: #fcc313;
            //box-shadow: 0 0 20px 2px $main-color;
          }
        }
        &.item-4 {
          width: 10%;
          .item-4-fill {
            position: absolute;
            width: 0%;
            height: 14px;
            left: 0;
            bottom: 0;
            background-color: #fcc313;
            //box-shadow: 0 0 20px 2px $main-color;
          }
        }
        &.item-5 {
          width: 12%;
          .item-5-fill {
            position: absolute;
            width: 0%;
            height: 14px;
            left: 0;
            bottom: 0;
            background-color: #fcc313;
            //box-shadow: 0 0 20px 2px $main-color;
          }
        }
        &.item-6 {
          width: 5%;
          .item-6-fill {
            position: absolute;
            width: 0%;
            height: 14px;
            left: 0;
            bottom: 0;
            background-color: #fcc313;
            //box-shadow: 0 0 20px 2px $main-color;
          }
        }
        &.item-7 {
          width: 9%;
          .item-7-fill {
            position: absolute;
            width: 0%;
            height: 14px;
            left: 0;
            bottom: 0;
            background-color: #fcc313;
            //box-shadow: 0 0 20px 2px $main-color;
          }
        }
        .item-percentage {
          font-size: 19px;
          line-height: 10px;
          text-align: right;
          position: absolute;
          top: -128px;
          right: 0;
        }
        .item-desc {
          font-family: $font-n-light;
          font-size: 14px;
          line-height: 17px;
          text-align: right;
          position: absolute;
          top: -108px;
          right: 0;
        }
        &:after {
          content: '';
          width: 2px;
          height: 28px;
          background-color: #354047;
          position: absolute;
          top: -52px;
          right: 0;
        }
      }
    }
    .distribution-bottom-text-1 {
      width: 715px;
      font-family: $font-n-book;
      font-size: 22px;
      line-height: 42px;
      position: absolute;
      top: 1230px;
      left: 0;
      opacity: 0;
      animation-delay: .5s;
      animation-duration: 1s;
    }
  }
}

/* steps-2 */
.steps-2 {
  color: #fff;
  position: relative;
  .outer-wrapper-1 {
    padding: 100px 0 100px;
    background-color: #7b8887;
  }
  .outer-wrapper-2 {
    padding: 100px 0 200px;
    background-color: #6e7c7b;
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    &.wrapper-header {
      height: 300px;
    }
    .steps-2-title {
      width: 690px;
      font-size: 62px;
      line-height: 67px;
      position: absolute;
      top: 0px;
      left: 0;
      opacity: 0;
      animation-delay: .5s;
      animation-duration: 1s;
    }
    .steps-2-text {
      width: 750px;
      font-family: $font-n-book;
      font-size: 22px;
      line-height: 42px;
      position: absolute;
      top: 0px;
      right: 150px;
      opacity: 0;
      animation-delay: 1.5s;
      animation-duration: 1s;
    }
    .item {
      width: 25%;
      &-title {
        font-size: 62px;
      }
      &-text {
        font-family: $font-n-book;
        font-size: 22px;
        line-height: 42px;
        margin-top: 77px;
      }
    }
  }
}

/* earth */
.earth-video-mob {
  position: relative;
  display: none;
  background: #050e1a;
  color: #fff;
  //video {
  //  display: block;
  //  width: 100%;
  //  height: auto;
  //}
  img {
    width: 100%;
    height: auto;
  }
  &-title {
    margin-top: 20px;
    font-size: 53px;
    line-height: 57px;
  }
  &-text {
    font-size: 22px;
    line-height: 27px;
    margin-top: 20px;
  }
}
.earth {
  color: #fff;
  height: 700px;
  padding: 100px 0 50px;
  position: relative;
  background: #000616 url(../img/earth-bg.png) no-repeat center center;
  background-size: contain;
  &-bgvideo {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    min-height: 100%;
  }
  .wrapper {
    position: relative;
    height: 100%;
    .earth-video-title {
      font-size: 62px;
      line-height: 67px;
      width: 50%;
      position: absolute;
      top: 100px;
      left: 0;
      z-index: 2;
      opacity: 0;
      animation-delay: .5s;
      animation-duration: 1s;
    }
    .earth-video-text {
      font-size: 43px;
      line-height: 59px;
      width: 55%;
      position: absolute;
      top: 300px;
      left: 0;
      z-index: 2;
      opacity: 0;
      animation-delay: 1s;
      animation-duration: 1s;
    }
    .player-wrapper {
      width: 50%;
      float: right;
      height: 100%;
      .owl-carousel {
        height: 100%;
        .owl-stage-outer {
          height: 100%;
          .owl-stage {
            height: 100%;
            .owl-item {
              height: 100%;
            }
          }
        }
      }
      .owl-carousel .slide span {
        font-family: $font-n-book;
        font-size: 22px;
        line-height: 32px;
        color: #fff;
        position: absolute;
        right: 0;
        top: 40%;
        width: 72%;
      }
      .owl-carousel .owl-dots {
        position: absolute;
        bottom: 0;
        left: -800px;
        .owl-dot {
          display: inline-block;
          width: 64px;
          height: 5px;
          background: #2f3741;
          margin-right: 47px;
          &.active {
            background: #fff;
          }
        }
      }
    }
  }
}

/* scp */
.scps {
  background: #000616;
  color: #fff;
  padding: 150px 0 150px;
  position: relative;
  .wrapper {
    display: flex;
    justify-content: space-between;
    .scp {
      position: relative;
      width: 20%;
      padding-left: 70px;
      &-image {
        position: absolute;
        top: 0;
        left: 0;
      }
      &-title {
        font-size: 28px;
        color: #fcc214;
        width: 80%;
        word-wrap: break-word;
      }
      &-text {
        font-family: $font-n-book;
        font-size: 22px;
        line-height: 34px;
        margin-top: 50px;
      }
    }
  }
}

/* stellar */
.stellar {
  background: #6e7c7b;
  color: #fff;
  padding: 100px 0 100px;
  position: relative;
  .wrapper {
    position: relative;
    .stellar-title {
      font-size: 43px;
      line-height: 59px;
      width: 1200px;
      opacity: 0;
      animation-delay: .5s;
      animation-duration: 1s;
    }
    .stellar-button {
      @extend #button;
      position: absolute;
      top: 55px;
      right: 0;
      box-shadow: 0 0 50px -7px #000;
      opacity: 0;
      animation-delay: 1.5s;
      animation-duration: 1s;
      &-text {
        position: relative;
        right: 0;
        transition: right .2s;
      }
      &-arrow {
        @extend #arrow;
        //background-color: #3a3528;
        background-color: transparent;
        &:before, &:after {
          background-color: #3a3528;
        }
      }
      &:hover {
        box-shadow: 0 0 60px 0 $main-color;
      }
    }
  }
}

/* road-card */
.road-card::-webkit-scrollbar { width: 0; background: rgba(255, 255, 255, 0.0); }
.road-card { -ms-overflow-style: none; }
.road-card { overflow: -moz-scrollbars-none; }
.road-card {
  color: #fff;
  background: #050e1a;
  overflow: auto;
  cursor: move;
  position: relative;
  .wrapper {
    position: relative;
    .road-card-title {
      font-size: 62px;
      line-height: 67px;
      position: absolute;
      top: 162px;
      left: 0;
      pointer-events: none;
      z-index: 5;
      opacity: 0;
      animation-delay: 0s;
      animation-duration: 1s;
    }
    .road-card-text {
      font-family: $font-n-book;
      font-size: 22px;
      line-height: 42px;
      position: absolute;
      top: 250px;
      left: 0;
      pointer-events: none;
      z-index: 5;
      opacity: 0;
      animation-delay: 1s;
      animation-duration: 1s;
    }
    .road-card-percentage {
      font-family: $font-n-book;
      font-size: 62px;
      line-height: 32px;
      color: #fcc214;
      position: absolute;
      top: 194px;
      left: 52%;
      pointer-events: none;
      z-index: 5;
      opacity: 0;
      animation-delay: 2s;
      animation-duration: 1s;
      span {
        font-size: 22px;
      }
    }
  }
  .bar {
    position: relative;
    width: 4000px;
    &:before {
      content: "";
      position: absolute;
      top: 550px;
      left: 0;
      width: 100%;
      height: 2px;
      background: #6e7c7b;
      z-index: 5;
    }
    .wrapper {
      display: flex;
      justify-content: flex-start;
      position: relative;
      width: auto;
      margin-right: 90px;
      margin-left: 90px;
      .bar-item-wrapper {
        position: relative;
        width: 14.28%;
        padding: 550px 0 470px;
        transition: all .3s;
        .bar-item {
          position: relative;
          height: 2px;
          &.item-1, &.item-2, &.item-3, &.item-4 {
            .bar-item-fill {
              position: absolute;
              width: 100%;
              height: 6px;
              left: 0;
              bottom: -2px;
              background-color: #fcc313;
              box-shadow: 0 0 20px 2px $main-color;
              z-index: 10;
            }
            &:before {
              top: -10px;
              width: 21px;
              height: 21px;
              background-color: #fcc313;
              box-shadow: 0 0 20px 2px $main-color;
              z-index: 10;
            }
          }
          &.item-5:before {
            top: -10px;
            width: 21px;
            height: 21px;
            background-color: #fcc313;
            box-shadow: 0 0 20px 2px $main-color;
            z-index: 10;
          }
          .bar-item-month {
            font-family: $font-n-book;
            font-size: 24px;
            line-height: 28px;
            color: #fcc214;
            position: absolute;
            top: -120px;
            left: 0;
            transition: all .3s;
            &:before {
              content: '';
              position: absolute;
              top: 18px;
              left: -25px;
              width: 13px;
              height: 10px;
              background: url(../img/check.png);
            }
            &.disabled {
              color: #6e7c7b;
              &:before {
                display: none;
              }
              i {
                position: absolute;
                top: 10px;
                left: -34px;
              }
            }
          }
          .bar-item-title {
            font-family: $font-n-book;
            font-size: 22px;
            color: #fcc214;
            position: absolute;
            top: 74px;
            left: 0;
            padding-right: 20px;
            transition: all .3s;
            &.disabled {
              color: #6e7c7b;
            }
          }
          .bar-item-desc {
            font-family: $font-n-book;
            font-size: 19px;
            color: #fff;
            position: absolute;
            top: 180px;
            left: 0;
            transition: all .3s;
            opacity: 0;
            transition: all .3s;
          }
          &:before {
            content: '';
            position: absolute;
            top: -6px;
            left: 0;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background: #6e7c7b;
          }
        }
        &:hover {
          width: 20%;
          background-color: #0d1722;
          .bar-item-month {
            padding-left: 20px;
          }
          .bar-item-title, .bar-item-desc {
            padding: 0 40px 0 50px;
          }
          .bar-item-desc {
            opacity: 1;
          }
        }
      }
    }
  }
}

/* team */
.team {
  background: #050e1a;
  color: #fff;
  padding-bottom: 120px;
  position: relative;
  .wrapper {
    padding-top: 115px;
    .team-title {
      font-size: 62px;
      margin-right: 55px;
      cursor: pointer;
      &.inactive {
        font-size: 22px;
        color: #fcc214;
      }
    }
  }
  &-wrapper {
    width: 80%;
    margin: 0 auto;
    .owl-carousel {
      margin-top: 60px;
      .owl-item {
        touch-action: manipulation;
      }
      .owl-nav .owl-prev, .owl-nav .owl-next {
        position: absolute;
        width: 54px;
        height: 62px;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        background-color: rgba(255,255,255,.05);
        transition: background-color .2s;
        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: rgba(255,255,255,.5);
        }
        &:hover {
          background-color: rgba(255,255,255,.1);
        }
      }
    }
    .owl-carousel .owl-nav {
      display: block;
      .owl-prev, .owl-next {
        position: absolute;
        width: 100px;
        height: 100px;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        //background-color: rgba(255,255,255,.05);
        //transition: background-color .2s;
        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: rgba(255,255,255,.5);
        }
        &:hover {
          //background-color: rgba(255,255,255,.1);
        }
      }
    }
    .owl-carousel .owl-nav .owl-prev {
      left: -150px;
    }
    .owl-carousel .owl-nav .owl-prev:before {
      content: '';
      width: 30px;
      height: 1px;
      background: #fff;
      position: absolute;
      top: 39px;
      left: 50%;
      transform: translateX(-50%) rotate(-45deg);
    }
    .owl-carousel .owl-nav .owl-prev:after {
      content: '';
      width: 30px;
      height: 1px;
      background: #fff;
      position: absolute;
      bottom: 39px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
    .owl-carousel .owl-nav .owl-next {
      right: -150px;
    }
    .owl-carousel .owl-nav .owl-next:before {
      content: '';
      width: 30px;
      height: 1px;
      background: #fff;
      position: absolute;
      top: 39px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
    .owl-carousel .owl-nav .owl-next:after {
      content: '';
      width: 30px;
      height: 1px;
      background: #fff;
      position: absolute;
      bottom: 39px;
      left: 50%;
      transform: translateX(-50%) rotate(-45deg);
    }
    .slide-title {
      font-size: 40px;
      text-align: center;
      margin-top: 66px;
    }
    .slide-position {
      font-family: $font-n-book;
      font-size: 22px;
      color: #6e7c7b;
      text-align: center;
      margin-top: 13px;
    }
    .slide-social {
      display: table;
      margin: 13px auto 0;
      a {
        margin: 0 10px;
        color: #fff;
      }
    }
  }
}

/* media */
.media {
  background: #6e7c7b;
  color: #fff;
  padding: 120px 0 120px;
  position: relative;
  &-wrapper {
    width: 80%;
    margin: 0 auto;
    .media-title {
      font-size: 62px;
      text-align: center;
      opacity: 0;
      animation-delay: 0s;
      animation-duration: 1s;
    }
    .owl-carousel {
      margin-top: 60px;
      .owl-item {
        cursor: pointer;
        touch-action: manipulation;
        img {
          transition: all .3s;
        }
        &:hover {
          img {
            transform: scale(1.02);
          }
        }
      }
      .owl-nav {
        display: block;
      }
      .owl-nav .owl-prev, .owl-nav .owl-next {
        position: absolute;
        width: 100px;
        height: 100px;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        //background-color: rgba(255,255,255,.05);
        //transition: background-color .2s;
        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: rgba(255,255,255,.5);
        }
        &:hover {
          //background-color: rgba(255,255,255,.1);
        }
      }
    }
    .owl-carousel .owl-nav .owl-prev {
      left: -150px;
    }
    .owl-carousel .owl-nav .owl-prev:before {
      content: '';
      width: 30px;
      height: 1px;
      background: #fff;
      position: absolute;
      top: 39px;
      left: 50%;
      transform: translateX(-50%) rotate(-45deg);
    }
    .owl-carousel .owl-nav .owl-prev:after {
      content: '';
      width: 30px;
      height: 1px;
      background: #fff;
      position: absolute;
      bottom: 39px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
    .owl-carousel .owl-nav .owl-next {
      right: -150px;
    }
    .owl-carousel .owl-nav .owl-next:before {
      content: '';
      width: 30px;
      height: 1px;
      background: #fff;
      position: absolute;
      top: 39px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
    .owl-carousel .owl-nav .owl-next:after {
      content: '';
      width: 30px;
      height: 1px;
      background: #fff;
      position: absolute;
      bottom: 39px;
      left: 50%;
      transform: translateX(-50%) rotate(-45deg);
    }
    .slide-date {
      font-family: $font-n-book;
      font-size: 19px;
      margin-top: 60px;
    }
    .slide-title {
      font-size: 28px;
      line-height: 42px;
      margin-top: 15px;
    }
    .slide-text {
      font-family: $font-n-book;
      font-size: 22px;
      line-height: 42px;
      margin-top: 40px;
    }
    .slide-social {
      display: table;
      margin: 13px auto 0;
      a {
        margin: 0 10px;
        color: #fff;
      }
    }
  }
}

/* FAQ */
.faq {
  position: relative;
  background: #050e1a;
  color: #fff;
  padding: 114px 0 100px;
  &-image {
    position: absolute;
    top: 1400px;
    right: 0;
    z-index: 1;
  }
  .wrapper {
    position: relative;
    .faq-title {
      font-size: 62px;
      text-align: center;
      opacity: 0;
      animation-delay: 0s;
      animation-duration: 1s;
    }
    .faq-button-wrapper {
      position: absolute;
      top: 427px;
      right: 180px;
      &-text {
        font-family: $font-n-book;
        font-size: 22px;
        line-height: 34px;
      }
      .faq-button {
        @extend #button;
        position: relative;
        margin-top: 65px;
        box-shadow: 0 0 50px -7px $main-color;
        opacity: 0;
        animation-delay: .5s;
        animation-duration: 1s;
        &-text {
          position: relative;
          right: 0;
          transition: right .2s;
        }
        &-arrow {
          @extend #arrow;
          //background-color: #3a3528;
          background-color: transparent;
          &:before, &:after {
            background-color: #3a3528;
          }
        }
        &:hover {
          box-shadow: 0 0 60px 0 $main-color;
        }
      }
    }
    .faq-accordeon {
      padding-left: 100px;
      margin-top: 145px;
      width: 720px;
      position: relative;
      z-index: 1;
    }
    .acc-head {
      position: relative;
      font-family: $font-n-book;
      font-size: 28px;
      margin-top: 50px;
      cursor: pointer;
      i {
        position: absolute;
        top: 0;
        left: -80px;
      }
      &:hover {
        color: $main-color;
      }
    }
    .acc-body {
      font-family: $font-n-light;
      font-size: 22px;
      line-height: 32px;
      padding: 70px 0 25px;
      display: none;
      ul, {
        list-style: initial;
      }
      ol {
        list-style-type: decimal;
      }
    }
  }
}

/* support */
.support {
  background: #6e7c7b;
  color: #fff;
  padding: 100px 0 100px;
  position: relative;
  &-mob-img {
    display: none;
  }
  .wrapper {
    position: relative;
    .support-title, .support-text {
      font-size: 43px;
      line-height: 59px;
      width: 1250px;
    }
    .support-title {
      opacity: 0;
      animation-delay: 0s;
      animation-duration: 1s;
    }
    .support-text {
      margin-top: 40px;
      opacity: 0;
      animation-delay: 1s;
      animation-duration: 1s;
    }
    .support-button {
      @extend #button;
      position: absolute;
      top: 110px;
      right: 0;
      box-shadow: 0 0 70px -7px #000;
      z-index: 2;
      opacity: 0;
      animation-delay: 2s;
      animation-duration: 1s;
      &-text {
        position: relative;
        right: 0;
        transition: right .2s;
      }
      &-arrow {
        @extend #arrow;
        background-color: transparent;
        transition: right .2s;
        &:before, &:after {
          background-color: #3a3528;
        }
      }
      &:hover {
        box-shadow: 0 0 60px 0px $main-color;
      }
    }
  }
}

/* footer */
.footer {
  position: relative;
  background: #050e1a;
  color: #fff;
  padding-top: 215px;
  &-button {
    @extend #button;
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 60px;
    &-chevron {
      margin-right: 20px;
    }
    &-text {
      position: relative;
      right: 0;
    }
    &:hover {
      width: 334px;
      .footer-button-text {
        right: -14px;
      }
    }
  }
  &-button-small {
    display: none;
  }
  &-logo {
    display: inline-block;
    margin-left: 5%;
    .download {
      vertical-align: top;
      margin-top: 70px;
      font-size: 18px;
      text-align: center;
      span {
        color: $main-color;
        position: relative;
        top: -6px;
      }
      a {
        position: relative;
        top: 0;
        transition: top .3s;
        &:hover {
          top: -5px;
          &:before {
            @extend #aura-icon;
          }
        }
      }
      .android-icon {
        margin: 0 20px;
      }
    }
  }
  &-mail-form {
    padding-top: 5px;
    width: 670px;
    height: 80px;
    float: right;
    background: transparent;
    margin-top: 40px;
    transition: all .25s;
    form {
      position: relative;
    }
    &-email {
      width: calc(100% - 170px);
      padding: 24px 10px 26px 85px;
      margin-left: 75px;
      background-color: transparent;
      background: url(../img/footer-mail-icon.png) no-repeat 0 27px;
      font-size: 18px;
      color: #b1b1b1;
      border: none;
      outline: none;
      border-bottom: 1px solid #4e545c;
      transition: all .25s;
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #b1b1b1;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: #b1b1b1;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: #b1b1b1;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: #b1b1b1;
      }
      &:focus {
        &::-webkit-input-placeholder {
          opacity: .5;
        }
        &::-moz-placeholder {
          opacity: .5;
        }
        &:-ms-input-placeholder {
          opacity: .5;
        }
        &:-moz-placeholder {
          opacity: .5;
        }
      }
    }
    &-submit {
      position: absolute;
      top: 32px;
      right: 62px;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      transition: all .25s;
      &-inner {
        background-color: #b1b1b1;
        @extend #arrow;
        &:before, &:after {
          background-color: #b1b1b1;
        }
      }
    }
    &:hover {
      .footer-mail-form-email {
        padding-bottom: 8px;
        margin-left: 125px;
        padding-left: 35px;
      }
      .footer-mail-form-submit {
        right: 48px;
      }
    }
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    padding: 120px 0 40px 0;
    .footer-nav {
      a {
        position: relative;
        font-size: 16px;
        color: #fff;
        text-transform: uppercase;
        text-decoration: none;
        margin-right: 35px;
        &:hover {
          color: $main-color;
          &:after {
            content: '';
            width: 3px;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -10px;
          }
        }
      }
    }
    .footer-social-icons {
      a {
        margin-right: 30px;
        color: #fff;
        position: relative;
        top: 0;
        transition: top .3s;
        &:hover {
          top: -5px;
        }
      }
      br {
        display: none;
      }
    }
    .footer-copyright {
      text-align: right;
      font-family: $font-n-book;
      color: #b1b1b1;
      font-size: 16px;
      a {
        color: #b1b1b1;
      }
      .design {
        margin-top: 15px;
      }
    }
  }
}

/*********     popup     *********/

/* main-why popup */
.main-why-1-popup, .main-why-2-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #09111d;
  color: #fff;
  padding: 6em 5em;
  overflow-Y: auto;
  z-index: 1000;
  &-cross {
    position: absolute;
    top: 100px;
    right: 100px;
    cursor: pointer;
    transition: all .2s;
    &:hover {
      border-radius: 50%;
    }
  }
  &-title {
    font-size: 62px;
    line-height: 67px;
  }
  &-subtitle {
    width: 70%;
    font-size: 43px;
    line-height: 59px;
    margin-top: 50px;
  }
  &-text {
    width: 50%;
    font-family: $font-n-book;
    font-size: 22px;
    line-height: 42px;
    margin-top: 50px;
  }
}

/* main-video-popup */
.main-video-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #09111d;
  color: #fff;
  overflow-Y: auto;
  z-index: 1000;
  opacity: 0;
  transition: opacity 1.5s;
  &-cross {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    transition: all .2s;
    &:hover {
      border-radius: 50%;
    }
  }
  .video-container {
    position: relative;
    display: block;
    vertical-align: middle;
    margin: 100px auto 0;
    line-height: 0;
    width: 85%;
    max-width: 1400px;
    &-scaler {
      width: 100%;
      height: 0;
      overflow: hidden;
      padding-top: 56.25%;
      .video-iframe {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 8px rgba(0,0,0,0.6);
        background: #000;
      }
    }
  }
}

/* get-popup */
.get-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #09111d;
  color: #fff;
  overflow-Y: auto;
  z-index: 1000;
  &-logo {
    display: block;
    margin: 100px auto 0;
  }
  &-cross {
    position: absolute;
    top: 100px;
    right: 100px;
    cursor: pointer;
    transition: all .2s;
    &:hover {
      border-radius: 50%;
    }
  }
  &-form {
    width: 515px;
    margin: 0 auto;
    input {
      font-family: $font-n-medium;
      color: #fff;
      font-size: 19px;
      width: calc(100% - 60px);
      height: 70px;
      margin-top: 50px;
      background-color: transparent;
      border: none;
      outline: none;
      border-bottom: 1px solid #50565f;
      padding: 0 30px;
      transition: all .3s;
      &::-webkit-input-placeholder {
        color: #b1b1b1;
      }
      &::-moz-placeholder {
        color: #b1b1b1;
      }
      &:-ms-input-placeholder {
        color: #b1b1b1;
      }
      &:-moz-placeholder {
        color: #b1b1b1;
      }
      &:hover {
        border-bottom-color:  #fcc314;
        &::-webkit-input-placeholder {
          color: #fcc314;
        }
        &::-moz-placeholder {
          color: #fcc314;
        }
        &:-ms-input-placeholder {
          color: #fcc314;
        }
        &:-moz-placeholder {
          color: #fcc314;
        }
      }
      &:focus {
        &::-webkit-input-placeholder {
          opacity: .5;
        }
        &::-moz-placeholder {
          opacity: .5;
        }
        &:-ms-input-placeholder {
          opacity: .5;
        }
        &:-moz-placeholder {
          opacity: .5;
        }
      }
    }
    .get-popup-button {
      @extend #button;
      position: relative;
      display: block;
      margin: 100px auto;
      &-arrow {
        @extend #arrow;
        background-color: transparent;
        &:before, &:after {
          background-color: #3a3528;
        }
      }
    }
  }
}

/* download-popup */
.download-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #09111d;
  color: #fff;
  overflow-Y: auto;
  z-index: 1000;
  &-logo {
    display: block;
    margin: 100px auto 0;
  }
  &-cross {
    position: absolute;
    top: 100px;
    right: 100px;
    cursor: pointer;
    transition: all .2s;
    &:hover {
      border-radius: 50%;
    }
  }
  &-text {
    font-size: 28px;
    line-height: 35px;
    color: $main-color;
    text-align: center;
    padding: 0 20px;
    margin-top: 130px;
  }
  &-icons {
    width: 165px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    a {
      position: relative;
      transition: all .3s;
      &:hover:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 3px;
        height: 3px;
        box-shadow: 0 0 70px 20px $main-color;
      }
    }
  }
}

/* question-popup */
.question-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #09111d;
  color: #fff;
  overflow-Y: auto;
  z-index: 1000;
  &-logo {
    display: block;
    margin: 100px auto 0;
  }
  &-cross {
    position: absolute;
    top: 100px;
    right: 100px;
    cursor: pointer;
    transition: all .2s;
    &:hover {
      border-radius: 50%;
    }
  }
  &-form {
    width: 515px;
    margin: 0 auto;
    input, textarea {
      font-family: $font-n-medium;
      color: #fff;
      font-size: 19px;
      width: calc(100% - 60px);
      height: 70px;
      margin-top: 50px;
      background-color: transparent;
      border: none;
      outline: none;
      border-bottom: 1px solid #50565f;
      padding: 0 30px;
      resize: none;
      transition: all .3s;
      &::-webkit-input-placeholder {
        color: #b1b1b1;
      }
      &::-moz-placeholder {
        color: #b1b1b1;
      }
      &:-ms-input-placeholder {
        color: #b1b1b1;
      }
      &:-moz-placeholder {
        color: #b1b1b1;
      }
      &:hover {
        border-bottom-color:  #fcc314;
        &::-webkit-input-placeholder {
          color: #fcc314;
        }
        &::-moz-placeholder {
          color: #fcc314;
        }
        &:-ms-input-placeholder {
          color: #fcc314;
        }
        &:-moz-placeholder {
          color: #fcc314;
        }
      }
      &:focus {
        &::-webkit-input-placeholder {
          opacity: .5;
        }
        &::-moz-placeholder {
          opacity: .5;
        }
        &:-ms-input-placeholder {
          opacity: .5;
        }
        &:-moz-placeholder {
          opacity: .5;
        }
      }
    }
    .question-popup-button {
      @extend #button;
      position: relative;
      display: block;
      margin: 100px auto;
      &-arrow {
        @extend #arrow;
        background-color: transparent;
        &:before, &:after {
          background-color: #3a3528;
        }
      }
    }
  }
}

/* media-popup */
.media-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #6e7c7b;
  color: #fff;
  overflow-Y: auto;
  z-index: 1000;
  &-image {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    box-shadow: 24px 50px 140px -25px #000;
  }
  &-cross {
    position: absolute;
    top: 100px;
    right: 100px;
    cursor: pointer;
    transition: all .2s;
    &:hover {
      border-radius: 50%;
    }
  }
  &-content {
    position: absolute;
    left: 50%;
    width: 35%;
    .content-header {
      font-size: 62px;
      margin-top: 130px;
    }
    .content-date {
      font-family: $font-n-book;
      font-size: 19px;
      margin-top: 175px;
    }
    .content-title {
      font-size: 62px;
      margin-top: 70px;
    }
    .content-text {
      font-family: $font-n-book;
      font-size: 22px;
      line-height: 42px;
      margin-top: 70px;
    }
  }
}

/*********     animations     *********/

@keyframes bg-download-anim {
  from {
    position: fixed;
    opacity: 1;
  }
  to {
    opacity: 0;
    position: static;
    z-index: -10;
  }
}
.bg-download-anim {
  animation: 1s bg-download-anim 4.5s linear both;
}

@keyframes header-download-anim {
  from {
    transform: translate3d(0, 200px, 0);
    opacity: 0;
    line-height: 107px;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    line-height: 67px;
  }
}
.header-download-anim {
  animation: 2s header-download-anim 1s ease-in-out both;
}

@keyframes sub-header-download-anim {
  from {
    transform: translate3d(0, 100px, 0);
    opacity: 0;
    line-height: 53px;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    line-height: 33px;
  }
}
.sub-header-download-anim {
  animation: 2s sub-header-download-anim 2.5s ease-in-out both;
}

@keyframes header-anim {
  from {
    transform: translate3d(0, 100px, 0);
    opacity: 0;
    line-height: 107px;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    line-height: 67px;
  }
}
.header-anim {
  animation: header-anim ease-in-out both;
}

@keyframes header-anim-2 {
  from {
    transform: translate3d(0, 100px, 0);
    opacity: 0;
    line-height: 118px;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    line-height: 78px;
  }
}
.header-anim-2 {
  animation: header-anim-2 ease-in-out both;
}

@keyframes header-anim-3 {
  from {
    transform: translate3d(0, 100px, 0);
    opacity: 0;
    line-height: 103px;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    line-height: 63px;
  }
}
.header-anim-3 {
  animation: header-anim-3 ease-in-out both;
}

@keyframes sub-header-anim {
  from {
    transform: translate3d(0, 100px, 0);
    opacity: 0;
    line-height: 53px;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    line-height: 33px;
  }
}
.sub-header-anim {
  animation: sub-header-anim ease-in-out both;
}

@keyframes sub-header-anim-2 {
  from {
    transform: translate3d(0, 100px, 0);
    opacity: 0;
    line-height: 79px;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    line-height: 59px;
  }
}
.sub-header-anim-2 {
  animation: sub-header-anim-2 ease-in-out both;
}

@keyframes sub-header-anim-3 {
  from {
    transform: translate3d(0, 100px, 0);
    opacity: 0;
    line-height: 65px;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    line-height: 42px;
  }
}
.sub-header-anim-3 {
  animation: sub-header-anim-3 ease-in-out both;
}

@keyframes what-is-image-anim {
  from {
    transform: translate3d(-100px, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.what-is-image-anim {
  animation: what-is-image-anim ease-in-out both;
}

@keyframes about-us-anim {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.about-us-anim {
  animation: .5s about-us-anim linear both;
}

@keyframes about-us-reverse-anim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.about-us-reverse-anim {
  animation: .5s about-us-reverse-anim linear both;
}

@keyframes multi-sig-image-anim {
  from {
    transform: translate3d(0, 200px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.multi-sig-image-anim {
  animation: multi-sig-image-anim 3s ease-in-out both;
}

@keyframes multi-sig-text-anim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.multi-sig-text-anim {
  animation: multi-sig-text-anim linear both;
}

@keyframes era-anim {
  from {
    opacity: 0;
    background: #000 url(../img/era-bg.jpg) no-repeat -700px -350px;
  }
  to {
    opacity: 1;
    background: #000 url(../img/era-bg.jpg) no-repeat -400px -350px;
  }
}
.era-anim {
  animation: 1s era-anim linear both;
}

@keyframes era-reverse-anim {
  from {
    opacity: 1;
    background: #000 url(../img/era-bg.jpg) no-repeat -400px -350px;
  }
  to {
    opacity: 0;
    background: #000 url(../img/era-bg.jpg) no-repeat -700px -350px;
  }
}
.era-reverse-anim {
  animation: 1s era-reverse-anim linear both;
}

@keyframes era-mob-anim {
  from {
    background: #000 url(../img/era-bg.jpg) no-repeat -800px -350px;
  }
  to {
    opacity: 1;
    background: #000;
  }
}
.era-mob-anim {
  animation: 1s era-mob-anim linear both;
}

@keyframes building-1-anim {
  from {
    transform: translate3d(-150px, 300px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.building-1-anim {
  animation: building-1-anim 3s ease-in-out both;
}

@keyframes building-2-anim {
  from {
    transform: translate3d(150px, 300px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.building-2-anim {
  animation: building-2-anim 3s ease-in-out both;
}

@keyframes items-anim {
  from {
    transform: translate3d(0, 200px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.items-anim {
  animation: items-anim 1.5s ease-in-out .5s both;
}

@keyframes p2p-image-anim {
  from {
    transform: translate3d(0, 300px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.p2p-image-anim {
  animation: p2p-image-anim ease-in-out both;
}


/*********     media-queries     *********/

@media screen and (max-width: 1900px) {
  .header-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .header .logo, .header .nav, .header .social-icons {
    margin-right: 10px;
  }
  .header .lang {
    padding-right: 45px;
  }
  html .wrapper, body .wrapper {
    width: 94%;
  }
  .main-screen .main-media {
    left: calc(3% + 10px);
  }
  .what-is {
    background-position-x: center;
  }
  .what-is-content {
    margin-left: 3%;
  }
  .dex-video .wrapper .player-wrapper {
    width: 68%;
  }
  .dex-video .wrapper .player-wrapper .owl-carousel .owl-dots {
    display: none;
  }
  .price .price-items .price-item.item-3 .price-item-discount {
    top: 53%;
  }
  .ideology-title {
    top: 105px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .ideology-text {
    right: auto;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
  }
  .support .wrapper .support-title, .support .wrapper .support-text {
    width: 68%;
  }
  .stellar .wrapper .stellar-title {
    width: 65%;
  }
}

@media screen and (max-width: 1800px) {
  .main-screen {
    height: calc(100vh - 150px);
  }
  .what-is {
    margin-top: calc(100vh - 150px);
  }
  .what-is-total {
    padding-top: 40px;
  }
  .what-is-bar {
    margin-top: 30px;
  }
  .what-is-mail {
    width: 496px;
    .mail-text-info {
      position: absolute;
      top: 10px;
      left: 75px;
      margin-top: 0;
      width: 315px;
      color: #3a3528;
      font-size: 18px;
    }
    .mail-form {
      height: 90px;
      padding-top: 60px;
    }
  }
}

@media screen and (max-width: 1750px) {
  .bg-download .wrapper .main-text, .main-screen .wrapper .main-text {
    width: 750px;
    top: 100px;
  }
  .main-screen .main-why {
    top: 140px;
  }
  .what-is-content {
    p {
      width: 650px;
    }
    .video-wrapper {
      left: auto;
      right: 300px;
    }
  }
  .dex .dex-image {
    right: -300px;
  }
  .price .price-items .price-item-button:hover .button-text {
    right: initial;
  }
  .price .price-items .price-item-button .button-text {
    display: block;
    padding-left: 0;
    text-align: center;
  }
  .price .price-items .price-item-button-arrow {
    display: none;
  }
  .usefulness .wrapper .usefulness-text-2 {
    top: 450px;
  }
  .steps-2 .wrapper.wrapper-header {
    height: 500px;
    .steps-2-text {
      top: 200px;
    }
  }
  .earth .wrapper .dex-video-text-1, .earth .wrapper .dex-video-text-2 {
    width: 48%;
  }
  .earth .wrapper .player-wrapper .owl-carousel .owl-dots {
    left: 0;
  }
  .road-card .wrapper {
    .road-card-title {
      top: 60px;
    }
    .road-card-text {
      top: 150px;
    }
    .road-card-percentage {
      top: 240px;
    }
  }
}

@media screen and (max-width: 1650px) {
  .header .nav a:last-child {
    margin-right: 0;
  }
  .bg-download .wrapper .main-text, .main-screen .wrapper .main-text {
    top: 100px;
  }
  .footer .wrapper {
    flex-wrap: wrap;
    position: relative;
    .footer-nav, .footer-copyright {
      width: 50%;
    }
    .footer-nav a {
      margin-right: 20px;
      white-space: nowrap;
    }
    .footer-social-icons {
      position: absolute;
      left: 0;
      bottom: 40px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .wallet2-content {
    left: 50px;
  }
  .p2p .p2p-image.image-2 {
    display: none;
  }
  .team-wrapper .owl-carousel .owl-nav .owl-prev,
  .media-wrapper .owl-carousel .owl-nav .owl-prev {
    left: -110px;
  }
  .team-wrapper .owl-carousel .owl-nav .owl-next,
  .media-wrapper .owl-carousel .owl-nav .owl-next {
    right: -110px;
  }
  .media-popup-image {
    transform: translate(-20%, -50%);
  }
}

@media screen and (max-width: 1500px) {
  .header .social-icons {
    display: none;
  }
  .bg-download .wrapper .main-text-header, .main-screen .wrapper .main-text-header {
    font-size: 55px;
    line-height: 60px;
  }
  .bg-download .wrapper  .main-text-desc, .main-screen .wrapper .main-text-desc {
    width: 600px;
    font-size: 23px;
    line-height: 28px;
  }
  .main-screen .main-why {
    font-size: 23px;
    line-height: 27px;
  }
  .main-screen .main-countdown {
    width: 490px;
  }
  .main-screen .main-countdown-top {
    .text-color-1 {
      padding-left: 5px;
    }
    .text-color-3 {
      padding-left: 15px;
    }
  }
  .main-screen .main-countdown-counter {
    .counter-figure {
      font-size: 80px;
    }
    .counter-colon {
      font-size: 80px;
      margin: 0 14px;
    }
  }
  .what-is-total {
    margin-left: 3%;
    margin-right: 15px;
  }
  #header-2, .what-is-content h2, .about-us h2, .multi-sig .wrapper .sig-content h2,
  .multi-sig .wrapper .wallet-content h2, .era-wrapper .era .wrapper .era-title,
  .era-wrapper .era-bottom-content-title, .chart .wrapper .chart-body-title,
  .p2p .wrapper .p2p-title, .dex .wrapper .dex-title, .rocket .wrapper .rocket-title,
  .main-why-1-popup-title, .main-why-2-popup-title {
    font-size: 55px;
    line-height: 60px;
  }
  .main-why-1-popup-subtitle, .main-why-2-popup-subtitle {
    font-size: 36px;
    line-height: 46px;
  }
  .what-is-content p, .dex .wrapper .dex-text-1, .dex .wrapper .dex-text-2 {
    font-size: 30px;
  }
  .main-why-1-popup-text, .main-why-2-popup-text {
    font-size: 20px;
    line-height: 36px;
  }
  .about-us .about-items {
    width: 100%;
  }
  .wallet2-advs1, .wallet2-advs2 {
    right: 100px;
  }
  .distribution .wrapper .bar-1-item.item-1 .item-desc {
    width: 28%;
  }
  .chart .wrapper .chart-body-text2 {
    top: 1880px;
  }
  .ideology-items .ideology-item {
    padding-top: 490px;
    padding-left: 20px;
  }
  .ideology-items .ideology-item-wrapper {
    padding: 0 40px 40px 60px;
  }
  .dex-advs {
    padding-bottom: 275px;
  }
  .dex-advs-button {
    top: 300px;
  }
  .dex-video .wrapper .dex-video-text {
    width: 350px;
  }
  .earth {
    height: 600px;
  }
  .earth .wrapper .earth-video-title {
    top: 50px;
  }
  .earth .wrapper .earth-video-text-1, .earth .wrapper .earth-video-text-2 {
    top: 250px;
  }
  .earth .wrapper .player-wrapper {
    width: 50%;
  }
  .earth .wrapper .player-wrapper .owl-carousel .owl-dots {
    left: -100px;
    bottom: 30px;
  }
  .faq .wrapper .faq-button-wrapper {
    right: 50px;
  }
  @keyframes header-download-anim {
    from {
      transform: translate3d(0, 200px, 0);
      opacity: 0;
      line-height: 107px;
    }
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      line-height: 60px;
    }
  }
  @keyframes sub-header-download-anim {
    from {
      transform: translate3d(0, 100px, 0);
      opacity: 0;
      line-height: 53px;
    }
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      line-height: 28px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .what-is-total {
    display: none;
  }
  .what-is-bar {
    margin-left: 3%;
  }
  .what-is-content p {
    width: 470px;
  }
  .dex-video .wrapper {
    height: 792px;
  }
  .dex-video .wrapper .player-wrapper .owl-carousel .item-video {
    height: 665px;
  }
  .faq {
    padding-bottom: 250px;
    .wrapper .faq-button-wrapper {
      top: auto;
      left: 100px;
      bottom: -200px;
      .faq-button {
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .era-wrapper .era-building-1 {
    left: -150px;
  }
  .era-wrapper .era-building-2 {
    right: -150px;
  }
  .dex-video .wrapper {
    height: 717px;
  }
  .dex-video .wrapper .player-wrapper .owl-carousel .item-video {
    height: 590px;
  }
  .earth .wrapper .earth-video-title {
    font-size: 52px;
  }
  .earth .wrapper .earth-video-text {
    top: 200px;
    font-size: 34px;
  }
  .footer {
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-button {
      display: none;
    }
    &-button-small {
      display: block;
      position: absolute;
      top: -180px;
      right: 20px;
      width: 50px;
      height: 50px;
      border: 1px solid $main-color;
      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $main-color;
      }
      &:hover {
        background-color: $main-color;
        i {
          color: #050e1a;
        }
      }
    }
    .footer-logo {
      margin-left: 0;
      .download {
        margin-top: 25px;
      }
    }
    .footer-mail-form {
      margin-top: 15px;
      &-email {
        margin-left: 30px;
      }
      &:hover {
        .footer-mail-form-email {
          padding-bottom: 26px;
          margin-left: 30px;
          padding-left: 85px;
        }
        .footer-mail-form-submit {
          right: 62px;
        }
      }
    }
    .wrapper {
      flex-direction: column;
      align-items: center;
      padding-top: 40px;
      .footer-nav {
        width: initial;
      }
      .footer-social-icons {
        position: static;
        margin-top: 30px;
        margin-bottom: 30px;
        a {
          margin: 0 15px;
        }
      }
      .footer-copyright {
        width: initial;
        text-align: center;
      }
    }
  }
  .media-popup-image {
    transform: translate(-30%, -50%);
  }
}

@media screen and (max-width: 1200px) {
  .header {
    .lang, .nav {
      display: none;
    }
    .burger {
      display: block;
    }
  }
  .bg-download .wrapper .main-text, .main-screen .wrapper .main-text,
  .bg-download .wrapper  .main-text-desc, .main-screen .wrapper .main-text-desc {
    width: 100%;
  }
  .main-screen {
    .main-why {
      display: none;
    }
    .main-media-whitepaper {
      margin-right: 30px;
    }
  }
  .what-is-bar {
    width: 440px;
  }
  .what-is .wrapper {
    min-height: 300px;
    .what-is-footer {
      flex-wrap: wrap;
      .item {
        width: 45%;
        padding-top: 90px;
        margin-bottom: 70px;
        &-text {
          margin-top: 20px;
        }
      }
    }
  }
  .what-is-content {
    h2 {
      width: 300px;
      font-size: 30px;
      line-height: 35px;
    }
    p {
      width: 300px;
      font-size: 24px;
      line-height: 30px;
    }
  }
  .about-us .about-items .about-item {
    width: 285px;
    height: 285px;
  }
  .dex .wrapper .dex-text-2 {
    width: 100%;
  }
  .price .price-items .price-item.item-1:hover, .price .price-items .price-item.item-2:hover,
  .price .price-items .price-item.item-3:hover,
  .price .price-items .price-item.item-4:hover, .price .price-items .price-item.item-5:hover, .price .price-items .price-item.item-6:hover {
    padding-left: 10px;
    padding-right: 10px;
  }
  .price .price-items .price-item.item-3:hover .price-item-discount {
    padding: 10px 10px;
  }
  .price .price-items .price-item .dot {
    margin: 0;
  }
  .scps {
    padding-top: 80px;
    padding-bottom: 80px;
    .wrapper {
      flex-wrap: wrap;
      .scp {
        width: 40%;
        margin-bottom: 70px;
      }
    }
  }
  .dex-advs-title {
    width: 100%;
  }
  .earth .wrapper .player-wrapper .owl-carousel .slide span {
    font-size: 20px;
    width: 90%;
  }
  .team-wrapper .owl-carousel .owl-nav .owl-prev, .media-wrapper .owl-carousel .owl-nav .owl-prev {
    left: -80px;
    width: 60px;
  }
  .team-wrapper .owl-carousel .owl-nav .owl-next, .media-wrapper .owl-carousel .owl-nav .owl-next {
    right: -80px;
    width: 60px;
  }
  .main-why-1-popup-subtitle, .main-why-2-popup-subtitle, .main-why-1-popup-text, .main-why-2-popup-text {
    width: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .earth .wrapper .player-wrapper .owl-carousel .owl-dots {
    bottom: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .bg-download .wrapper {
    .main-text {
      top: 75px;
      &-desc {
        margin-left: 0;
      }
    }
  }
  .header .download a:hover {
    top: 0;
    &:before {
      display: none;
    }
  }
  .bgvideo {
    display: none;
  }
  .main-screen {
    position: static;
    height: auto;
    z-index: 1;
    transform: none;
    .wrapper {
      .main-text {
        position: initial;
        margin-top: 75px;
        &-desc {
          margin-left: 0;
        }
      }
    }
    .main-media {
      position: static;
      margin: 30px 0 50px 3%;
    }
    .main-media-whitepaper:hover img, .main-media-video:hover img {
      transform: scale(1);
    }
    .main-media-whitepaper:hover span, .main-media-video:hover span {
      right: 0;
    }
    .main-media-whitepaper:hover span:after, .main-media-video:hover span:after {
      width: 0;
    }
  }
  .main-countdown {
    display: none;
  }
  .countdown-mobile {
    display: block;
  }
  .what-is {
    //background: #050e1a;
    background: #6F7C7C;
    padding-bottom: 0;
    margin-top: 0;
    overflow: hidden;
    &-total {
      display: block;
      text-align: center;
      .total-digit {
        margin-bottom: 10px;
        font-size: 34px;
      }
      .total-dot {
        margin: 0 10px;
      }
    }
    &-bar {
      width: 94%;
      margin: 45px 3% 0;
    }
  }
  .what-is-mail {
    display: none;
  }
  .what-is-content {
    position: relative;
    margin-top: 30px;
    min-height: auto;
    margin-right: 3%;
    .what-is-image {
      display: none;
    }
    //.what-is-mob-image {
    //  position: relative;
    //  margin: 0 -3%;
    //  display: block;
    //  width: 106%;
    //  &:after {
    //    content: '';
    //    position: absolute;
    //    bottom: 2px;
    //    left: 0;
    //    right: 0;
    //    height: 6px;
    //    background-color: #6F7C7C;
    //  }
    //  video {
    //    width: 100%;
    //    width: 100%;
    //  }
      //img {
      //  width: 100%;
      //  height: auto;
      //}
    //}
    h2 {
      position: static;
      opacity: 1;
      padding-top: 15px;
    }
    p {
      position: static;
      opacity: 1;
      top: 920px;
      width: 100%;
      margin: 20px 0 70px 0;
    }
    .what-is-store {
      display: none;
    }
  }
  .what-is .wrapper {
    opacity: 1;
    .what-is-footer {
      position: static;
    }
  }
  .about-us {
    background: #050e1a;
    padding-top: 40px;
    padding-bottom: 0;
    animation: multi-sig-text-anim linear both;
    h2 {
      opacity: 1;
    }
    .about-items {
      opacity: 1;
      .about-item {
        width: 250px;
        height: 250px;
        &:hover {
          background-color: #050e1a;
          &:before {
            background-position: 0 0;
          }
        }
      }
    }
  }
  .multi-sig {
    background: #050e1a;
    margin-top: 0;
    min-height: auto;
    padding: 30px 0;
    .wrapper {
      .sig-icons {
        display: none !important;
      }
      .sig-content {
        position: static;
        width: 100%;
        height: auto;
        //.sig-mob-img-1 {
        //  display: block;
        //  margin: 0 -3%;
        //  margin-bottom: -100px;
        //  img {
        //    width: 100%;
        //    height: auto;
        //  }
        //}
        h2 {
          opacity: 1;
          word-wrap: break-word;
        }
        p {
          opacity: 1;
          margin-top: 30px;
        }
      }
      .wallet-content {
        position: static;
        width: 100%;
        margin-top: 40px;
        height: auto;
        .sig-mob-img-2 {
          display: block;
          margin: 0 -3%;
          margin-bottom: -100px;
          img {
            width: 100%;
            height: auto;
          }
        }
        h2 {
          opacity: 1;
        }
        p {
          opacity: 1;
          margin-top: 30px;
        }
      }
      .sig-img-1, .wallet-img-1 {
        display: none;
      }
    }
  }
  .multi-sig-store {
    display: none;
  }
  .wallet2 {
    background: #050e1a;
    padding-top: 50px;
    padding-bottom: 30px;
    min-height: auto;
    .wrapper {
      .wallet2-advs {
        opacity: 1;
        flex-wrap: wrap;
        .adv-image {
          top: -10px
        }
        .adv {
          flex-basis: 100%;
          margin: 30px 0;
          .adv-text {
            margin-top: 20px;

          }
        }
        &.wallet2-advs2 .adv:last-child img {
          top: -5px;
        }
      }
    }
  }
  .era-outer-title {
    display: none;
  }
  .era-wrapper {
    background-color: #000;
    padding: 0 0 30px;
    margin-top: 0;
    .era {
      opacity: 1;
      height: auto;
      animation: 1s era-mob-anim linear both;
      .era-mob-img {
        display: block;
        margin-bottom: -200px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .era-building-1, .era-building-2 {
        display: none;
      }
      .wrapper {
        .era-title {
          display: block;
          position: static;
          opacity: 1;
          font-size: 53px;
          line-height: 57px;
          width: 100%;
        }
        .era-text {
          position: relative;
          top: 0;
          opacity: 1;
          width: 100%;
          line-height: 27px;
          margin-top: 30px;
          z-index: 1;
        }
      }
      .era-bottom-content {
        position: relative;
        transform: none;
        width: 94%;
        margin: 150px auto 0;
        left: initial;
        bottom: initial;
        z-index: 1;
        &-title {
          position: static;
          opacity: 1;
          text-indent: 0;
          font-size: 53px;
          line-height: 57px;
        }
        &-text {
          position: static;
          opacity: 1;
          line-height: 27px;
          margin-top: 30px;
        }
      }
      .era-building-1-mob, .era-building-2-mob {
        display: block;
        position: absolute;
        bottom: 0;
      }
      .era-building-1-mob {
        left: 0;
        bottom: 40px;
      }
      .era-building-2-mob {
        right: 0;
        bottom: 0;
      }
    }
  }
  .advantages-1 {
    background: #050e1a;
    padding: 50px 0 50px;
    .wrapper {
      flex-wrap: wrap;
      .advantage {
        width: 100%;
        margin-bottom: 30px;
        &-title {
          width: 100%;
        }
        &-text {
          margin-top: 20px;
        }
      }
    }
  }
  .chart {
    background: #050e1a;
    padding: 30px 0 30px;
    height: auto;
    .wrapper {
      .chart-header-text {
        position: static;
        opacity: 1;
        width: 100%;
        line-height: 54px;
        margin-top: 200px;
      }
      .chart-header-button {
        position: relative;
        opacity: 1;
        top: 0;
        left: 0;
        margin-top: 50px;
        font-size: 13px;
        color: #3a3528;
        background-color: #fcc214;
        width: 220px;
        height: 55px;
        cursor: pointer;
        .button-text {
          position: relative;
          padding-left: 20px;
          line-height: 55px;
          i {
            margin-right: 16px;
          }
        }
        &-arrow {
          right: 20px;
        }
        &:hover {
          width: 220px;
          height: 55px;
          .button-text {
            line-height: 55px;
            right: initial;
          }
          .button-arrow {
            right: 20px;
          }
        }
      }
      .chart-cube-mob {
        display: block;
        margin: 70px -3% 15px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .chart-body-title {
        position: static;
        opacity: 1;
        font-size: 53px;
        line-height: 57px;
        width: 100%;
        word-wrap: break-word;
      }
      .chart-body-text1, .chart-body-text2 {
        position: static;
        opacity: 1;
        line-height: 27px;
        margin-top: 20px;
        width: 100%;
      }
      .chart-body-cube {
        display: none;
      }
    }
    &-image {
      top: 0;
    }
  }
  .advantages-2 {
    background: #050e1a;
    padding: 50px 0 50px;
    .wrapper {
      flex-wrap: wrap;
      opacity: 1;
      padding: 0;
      .item {
        width: 100%;
        margin-bottom: 30px;
        &-title {
          color: $main-color;
        }
        &-text {
          margin-top: 20px;
        }
      }
      .advantages-2-video, .advantages-2-button {
        display: none;
      }
    }
  }
  .rocket {
    background: #050e1a;
    padding: 0 0 50px;
    .wrapper {
      height: auto;
      .rocket-title {
        position: static;
        opacity: 1;
        width: 100%;
        font-size: 53px;
        line-height: 57px;
      }
      .rocket-text1 {
        position: static;
        opacity: 1;
        line-height: 27px;
        margin-top: 20px;
        width: 100%;
      }
    }
  }
  .advantages-3 {
    background-color: #050e1a;
    padding: 50px 0 50px;
    .wrapper {
      opacity: 1;
      flex-wrap: wrap;
      .advantage {
        width: 100%;
        margin-bottom: 30px;
        padding-right: 20px;
        &-title {
          width: 100%;
        }
        &-text {
          margin-top: 20px;
        }
      }
    }
  }
  .p2p {
    background-color: #050e1a;
    padding: 0 0 50px;
    height: auto;
    .p2p-image {
      display: none;
    }
    .wrapper {
      //.p2p-image-mob {
      //  display: block;
      //  margin: 0 -3%;
      //  img {
      //    width: 100%;
      //    height: auto;
      //  }
      //}
      .p2p-title {
        position: relative;
        opacity: 1;
        top: 0px;
        width: 100%;
        font-size: 53px;
        line-height: 57px;
        word-wrap: break-word;
        //margin-top: 20px;
      }
      .p2p-text {
        position: relative;
        opacity: 1;
        top: 0;
        line-height: 27px;
        margin-top: 20px;
        width: 100%;
      }
    }
    .p2p-video, .p2p-button {
      display: none;
    }
  }
  .dex {
    background-color: #050e1a;
    padding: 0 0 50px;
    height: auto;
    .dex-image {
      display: none;
    }
    .dex-image-mob {
      display: block;
      margin-top: 50px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .wrapper {
      .dex-title {
        position: relative;
        top: 0;
        left: 0;
        opacity: 1;
        width: 100%;
        font-size: 53px;
        line-height: 57px;
      }
      .dex-title-desc, .dex-text-1, .dex-text-2 {
        position: relative;
        top: 0;
        left: 0;
        opacity: 1;
        font-size: 22px;
        line-height: 27px;
        margin-top: 20px;
        width: 100%;
      }
    }
  }
  .dex-advs {
    background: #050e1a;
    padding: 0 0 50px;
    .wrapper {
      flex-wrap: wrap;
      .item {
        width: 100%;
        padding-top: 90px;
        margin-bottom: 30px;
        &-title {
          color: $main-color;
        }
        &-text {
          margin-top: 20px;
        }
      }
      &.wrapper-2 {
        margin-top: 0;
      }
      &.wrapper-3 {
        display: none;
      }
    }
  }
  .dex-video {
    padding: 0 0 50px;
    .wrapper {
      height: auto;
      .dex-video-title, .dex-video-text {
        display: none;
      }
      .player-wrapper {
        width: 100%;
        float: none;
        box-shadow: none;
        .owl-carousel .owl-nav {
          display: none;
        }
        .owl-carousel .owl-dots {
          display: block;
          position: static;
          text-align: center;
          margin-top: 40px;
          .owl-dot {
            margin-left: 10px;
            margin-right: 10px;
          }
        }
      }
      .dex-video-channel {
        position: static;
        text-align: center;
        margin-top: 30px;
        &:hover {
          img {
            transform: scale(1);
          }
          span {
            right: 0;
            color: #fff;
            &:after {
              display: none;
            }
          }
        }
        a span {
          margin-right: 40px;
        }
      }
    }
  }
  .price {
    .wrapper {
      display: none;
    }
    .price-items {
      flex-direction: column;
      .price-item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        padding: 40px 10px 40px;
        &-box-1, &-box-2, &-box-3, &-box-4 {
          position: relative;
          width: 50%;
        }
        &-box-3, &-box-4 {
          margin-top: 50px;
        }
        &.item-3 {
          .price-item-xlm {
            position: relative;
            top: -25px;
          }
          .price-item-discount {
            top: 25px;
            right: 50%;
            transform: translateX(50%);
          }
        }
        &-xlm, &-total, &-remains {
          margin: 0;
        }
        &-stage, &-desc {
          display: inline-block;
        }
        &-desc {
          margin: 0 0 0 10px;
          position: relative;
          top: -15px;
        }
        &-button {
          display: none;
        }
      }
      .price-mob-button {
        display: block;
      }
    }
  }
  .ideology {
    background: #050e1a;
    padding: 50px 0 50px;
    &-title {
      position: static;
      margin: 0 3%;
      text-align: left;
      font-size: 53px;
      line-height: 57px;
      width: 94%;
    }
    &-text {
      position: static;
      text-align: left;
      font-size: 22px;
      line-height: 27px;
      width: 94%;
      margin: 20px 3% 0;
    }
    &-items {
      flex-direction: column;
      min-height: auto;
      margin: 40px 3% 0;
      .ideology-item {
        width: 100%;
        padding: 0;
        border: none;
        &-wrapper {
          .ideology-item-text {
            opacity: 1;
            margin-top: 20px;
          }
        }
        &:hover {
          background: initial;
          padding: 0;
        }
      }
    }
  }
  .usefulness {
    background-color: #050e1a;
    padding: 0;
    height: auto;
    .wrapper {
      .usefulness-title {
        position: static;
        opacity: 1;
        width: 100%;
        font-size: 53px;
        line-height: 57px;
      }
      .usefulness-text-1, .usefulness-text-2 {
        position: static;
        opacity: 1;
        width: 100%;
        font-size: 22px;
        line-height: 27px;
        margin-top: 20px;
      }
    }
  }
  .steps {
    background-color: #050e1a;
    padding: 0 0 50px;
    .outer-wrapper-1, .outer-wrapper-2 {
      background-color: #050e1a;
      padding: 0;
    }
    .wrapper {
      flex-direction: column;
      .item {
        width: 100%;
        margin-bottom: 30px;
        &-title {
          color: $main-color;
        }
        &-text {
          margin-top: 10px;
        }
      }
    }
    &.steps-2 .wrapper.wrapper-header {
      height: auto;
      padding-bottom: 50px;
    }
    &.steps-2 .wrapper .steps-2-title {
      position: static;
      opacity: 1;
      width: 100%;
      font-size: 53px;
      line-height: 57px;
    }
    &.steps-2 .wrapper .steps-2-text {
      position: static;
      opacity: 1;
      width: 100%;
      font-size: 22px;
      line-height: 27px;
      margin-top: 20px;
    }
  }
  .distribution {
    display: none;
  }
  .scps, .stellar {
    display: none;
  }
  .earth-video-mob {
    display: block;
  }
  .earth {
    background: #050e1a;
    height: auto;
    //video {
    //  display: none;
    //}
    .wrapper {
      .earth-video-title, .earth-video-text {
        display: none;
      }
      .player-wrapper {
        width: 100%;
        float: none;
        .owl-carousel {
          .slide span {
            position: static;
          }
          .owl-dots {
            top: -55px;
            left: 50%;
            transform: translateX(-50%);
            .owl-dot:first-child {
              width: 40px;
              margin-right: 10px;
            }
            .owl-dot:last-child {
              width: 40px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .road-card {
    .wrapper {
      display: none;
    }
    .bar {
      .wrapper {
        margin-left: 35px;
        margin-right: 35px;
        .bar-item-wrapper {
          padding-top: 125px;
          padding-bottom: 420px;
          .bar-item .bar-item-title, .bar-item-desc {
            padding: 0 5px;
          }
          .bar-item .bar-item-desc {
            opacity: 1;
          }
        }
        .bar-item-wrapper:hover {
          width: 14.28%;
          background-color: transparent;
          .bar-item-month {
            padding: 0;
          }
          .bar-item-title, .bar-item-desc {
            padding: 0 5px;
          }
        }
      }
      &:before {
        top: 125px;
      }
    }
  }
  .team {
    .wrapper {
      padding-top: 10px;
      .team-title, .team-title.inactive {
        font-size: 40px;
        margin-right: 0;
      }
      .team-title:first-child {
        margin-right: 40px;
      }
    }
  }
  .faq {
    padding: 50px 0 50px;
    //&-image {
    //  display: none;
    //}
    .wrapper {
      .faq-title {
        opacity: 1;
        font-size: 53px;
        text-align: left;
      }
      .faq-accordeon {
        width: calc(100% - 50px);
        padding-left: 50px;
        margin-top: 50px;
      }
      .acc-head i {
        left: -50px;
      }
      .acc-body {
        padding: 20px 0 0;
        line-height: 27px;
      }
      .faq-button-wrapper {
        position: static;
        opacity: 1;
        margin-top: 40px;
        .faq-button {
          opacity: 1;
          font-size: 13px;
          color: #3a3528;
          background-color: #fcc214;
          width: 220px;
          height: 55px;
          cursor: pointer;
          .button-text {
            position: relative;
            padding-left: 60px;
            line-height: 55px;
            i {
              margin-right: 16px;
            }
          }
          &-arrow {
            right: 20px;
          }
          &:hover {
            width: 220px;
            height: 55px;
            .button-text {
              line-height: 55px;
              right: initial;
            }
            .button-arrow {
              right: 20px;
            }
          }
        }
      }
    }
  }
  .support {
    padding: 50px 0 50px;
    //&-mob-img {
    //  display: block;
    //  margin-bottom: -80px;
    //  img {
    //    width: 100%;
    //    height: auto;
    //  }
    //}
    .wrapper {
      .support-title {
        position: static;
        opacity: 1;
        width: 100%;
        font-size: 53px;
        line-height: 57px;
      }
      .support-text {
        position: static;
        opacity: 1;
        width: 100%;
        font-size: 22px;
        line-height: 27px;
        margin-top: 20px;
      }
      .support-button {
        position: relative;
        opacity: 1;
        top: 0;
        box-shadow: 0 0 50px -7px #fcc313;
        margin-top: 40px;
        font-size: 13px;
        color: #3a3528;
        background-color: #fcc214;
        width: 220px;
        height: 55px;
        cursor: pointer;
        .button-text {
          position: relative;
          padding-left: 20px;
          line-height: 55px;
          i {
            margin-right: 16px;
          }
        }
        &-arrow {
          right: 20px;
        }
        &:hover {
          width: 220px;
          height: 55px;
          .button-text {
            line-height: 55px;
            right: initial;
          }
          .button-arrow {
            right: 20px;
          }
        }
      }
    }
  }
  .footer .wrapper {
    padding-bottom: 20px;
  }
  .main-why-1-popup-cross, .main-why-2-popup-cross {
    top: 30px;
    right: 30px;
  }
  .get-popup {
    &-logo {
      margin-top: 30px;
    }
    &-cross {
      top: 30px;
      right: 30px;
    }
    .get-popup-form {
      width: 90%;
      input {
        margin-top: 20px;
        height: 40px;
        font-size: 16px;
      }
    }
    .get-popup-form .get-popup-button {
      position: relative;
      opacity: 1;
      top: 0;
      left: 0;
      margin-top: 30px;
      font-size: 13px;
      color: #3a3528;
      background-color: #fcc214;
      width: 220px;
      height: 55px;
      cursor: pointer;
      .button-text {
        position: relative;
        padding-left: 20px;
        line-height: 55px;
        i {
          margin-right: 16px;
        }
      }
      &-arrow {
        right: 20px;
      }
      &:hover {
        width: 220px;
        height: 55px;
        .button-text {
          line-height: 55px;
          right: initial;
        }
        .button-arrow {
          right: 20px;
        }
      }
    }
  }
  .download-popup {
    &-logo {
      margin-top: 30px;
    }
    &-cross {
      top: 30px;
      right: 30px;
    }
    &-text {
      margin-top: 70px;
    }
    &-icons {
      margin-top: 50px;
    }
  }
  .question-popup {
    &-logo {
      margin-top: 30px;
    }
    &-cross {
      top: 30px;
      right: 30px;
    }
    .question-popup-form {
      width: 90%;
      input {
        margin-top: 20px;
        height: 40px;
        font-size: 16px;
      }
      textarea {
        font-size: 16px;
      }
    }
    .question-popup-form .question-popup-button {
      position: relative;
      opacity: 1;
      top: 0;
      left: 0;
      margin-top: 30px;
      font-size: 13px;
      color: #3a3528;
      background-color: #fcc214;
      width: 220px;
      height: 55px;
      cursor: pointer;
      .button-text {
        position: relative;
        padding-left: 20px;
        line-height: 55px;
        i {
          margin-right: 16px;
        }
      }
      &-arrow {
        right: 20px;
      }
      &:hover {
        width: 220px;
        height: 55px;
        .button-text {
          line-height: 55px;
          right: initial;
        }
        .button-arrow {
          right: 20px;
        }
      }
    }
  }
  .media-popup {
    &-image {
      top: 0;
      transform: translate(0);
      width: 100%;
    }
    &-cross {
      top: 20px;
      right: 20px;
      z-index: 1;
    }
    &-content {
      left: 10%;
      right: 10%;
      width: 80%;
      .content-header {
        margin-top: 60px;
      }
      .content-date {
        margin-top: 60px;
      }
      .content-title {
        margin-top: 60px;
      }
      .content-text {
        margin-top: 60px;
      }
    }
  }
  .footer-logo .download a:hover {
    top: 0;
    &:before {
      display: none;
    }
  }
}

@media screen and (max-width: 991px) {
  .what-is-footer {
    flex-wrap: wrap;
    .item {
      width: 45%;
      padding-top: 90px;
      margin-top: 30px;
    }
  }
  .about-us .about-items {
    width: 100%;
    .about-item {
      width: 265px;
      height: 265px;
    }
  }
}

@media screen and (max-width: 900px) {
  .about-us .about-items .about-item {
    width: 216px;
    height: 216px;
  }
  .team-wrapper .owl-carousel .owl-nav .owl-prev, .media-wrapper .owl-carousel .owl-nav .owl-prev {
    left: -50px;
    width: 50px;
  }
  .team-wrapper .owl-carousel .owl-nav .owl-next, .media-wrapper .owl-carousel .owl-nav .owl-next {
    right: -50px;
    width: 50px;
  }
}

@media screen and (max-width: 800px) {
  .chart-mob-image {
    display: block;
    margin-bottom: -270px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .chart-image {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  //.what-is-content {
  //  h2 {
  //    font-size: 48px;
  //  }
  //  p {
  //    font-size: 26px;
  //    line-height: 36px;
  //    margin-bottom: 40px;
  //  }
  //}
  .what-is .wrapper .what-is-footer {
    padding-bottom: 30px;
  }
  .what-is .wrapper .what-is-footer .item {
    width: 100%;
    padding-top: 0;
    padding-left: 75px;
    margin-bottom: 10px;
  }
  .what-is .wrapper .what-is-footer .item-title {
    margin-top: 5px;
  }
  .about-us {
    h2 {
      margin-bottom: 30px;
    }
    .about-items-wrapper {
      display: flex;
    }
    .about-items .about-item {
      height: 80px;
      margin-bottom: 5px;
    }
    /*.more {
      display: inline-block;
    }*/
  }
  .advantages-2 .wrapper .item {
    padding-top: 0;
    padding-left: 75px;
  }
  .advantages-2 .wrapper .item-title {
    margin-top: 5px;
  }
  .dex-advs .wrapper .item {
    padding-top: 0;
    padding-left: 75px;
  }
  .dex-advs .wrapper .item-title {
    margin-top: 5px;
  }
  .dex-video .wrapper .player-wrapper .owl-carousel .item-video {
    height: 480px;
  }
  .footer {
    .footer-mail-form {
      display: none;
    }
    .wrapper {
      .footer-nav {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .countdown-mobile {
    padding-bottom: 20px;
    .wrapper .countdown-mobile-button {
      float: none;
      margin-top: 35px;
    }
  }
}

@media screen and (max-width: 550px) {
  @keyframes header-download-anim {
    from {
      transform: translate3d(0, 200px, 0);
      opacity: 0;
      line-height: 107px;
    }
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      line-height: 38px;
    }
  }
  @keyframes sub-header-download-anim {
    from {
      transform: translate3d(0, 100px, 0);
      opacity: 0;
      line-height: 53px;
    }
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      line-height: 23px;
    }
  }
  .bg-download .wrapper .main-text-header, .main-screen .wrapper .main-text-header {
    font-size: 30px;
    line-height: 38px;
  }
  .bg-download .wrapper .main-text-desc, .main-screen .wrapper .main-text-desc {
    font-size: 18px;
    line-height: 23px;
  }
  .what-is-content h2, .about-us h2, .multi-sig .wrapper .sig-content h2,
  .multi-sig .wrapper .wallet-content h2, .era-wrapper .era .wrapper .era-title,
  .era-wrapper .era .era-bottom-content-title, .chart .wrapper .chart-body-title,
  .rocket .wrapper .rocket-title, .p2p .wrapper .p2p-title, .dex .wrapper .dex-title,
  .ideology-title, .usefulness .wrapper .usefulness-title, .steps-1 .wrapper .item-title,
  .steps-2 .wrapper .item-title, .steps.steps-2 .wrapper .steps-2-title, .earth-video-mob-title,
  .faq .wrapper .faq-title, .faq .wrapper .faq-button-wrapper-text, .support .wrapper .support-title {
    font-size: 30px;
    line-height: 38px;
  }
  .what-is-content p, .multi-sig .wrapper .sig-content p, .multi-sig .wrapper .wallet-content p,
  .wallet2 .wrapper .wallet2-advs .adv .adv-text, .era-wrapper .era .wrapper .era-text,
  .era-wrapper .era .era-bottom-content-text, .advantages-1 .wrapper .advantage-text,
  .chart .wrapper .chart-header-text, .chart .wrapper .chart-body-text1,
  .chart .wrapper .chart-body-text2, .advantages-2 .wrapper .item-text,
  .rocket .wrapper .rocket-text1, .advantages-3 .wrapper .advantage-text, .p2p .wrapper .p2p-text,
  .dex .wrapper .dex-title-desc, .dex .wrapper .dex-text-1, .dex .wrapper .dex-text-2,
  .dex-advs .wrapper .item-text,
  .ideology-text, .ideology-items .ideology-item-wrapper .ideology-item-text,
  .usefulness .wrapper .usefulness-text-1, .usefulness .wrapper .usefulness-text-2,
  .steps-1 .wrapper .item-text, .steps-2 .wrapper .item-text, .steps.steps-2 .wrapper .steps-2-text,
  .earth-video-mob-text, .earth .wrapper .player-wrapper .owl-carousel .slide span,
  .faq .wrapper .acc-body, .support .wrapper .support-text, .dex-video .wrapper .dex-video-channel a span {
    font-size: 18px;
    line-height: 23px;
  }
  .main-screen {
    .main-media-whitepaper, .main-media-video {
      font-size: 16px;
      img {
        margin-right: 0;
        width: 30px;
      }
    }
    .main-media-whitepaper {
      margin-right: 10px;
    }
  }
  .what-is-content p {
    margin-top: 10px;
  }
  .what-is .wrapper .what-is-footer {
    .item-title {
      font-size: 24px;
    }
    .item-text {
      font-size: 18px;
      line-height: 23px;
    }
  }
  .wallet2 .wrapper .wallet2-advs1 .adv-title, .wallet2 .wrapper .wallet2-advs2 .adv-title,
  .advantages-1 .wrapper .advantage-title, .advantages-2 .wrapper .item-title,
  .advantages-3 .wrapper .advantage-title, .ideology-items .ideology-item-wrapper .ideology-item-title, .dex-advs .wrapper .item-title, .faq .wrapper .acc-head {
    font-size: 24px;
  }
  .wallet2 .wrapper .wallet2-advs .adv,
  .advantages-1 .wrapper .advantage,
  .ideology-items .ideology-item-wrapper {
    cursor: pointer;
    padding-right: 20px;
  }
  .adv-after, .advantage-after, .ideology-after {
    text-shadow: 5px 0px 20px $main-color,
    0px 5px 20px $main-color,
    -5px 0px 20px $main-color,
    0px -5px 20px $main-color;
    &:after {
      display: none;
    }
  }
  .wallet2 .wrapper .wallet2-advs .adv .adv-title:before,
  .advantages-1 .wrapper .advantage-title:before,
  .advantages-3 .wrapper .advantage-title:before,
  .ideology-items .ideology-item-wrapper .ideology-item-title:before {
    content: '';
    width: 14px;
    height: 2px;
    background: #465256;
    position: absolute;
    top: 15px;
    right: 0;
  }
  .wallet2 .wrapper .wallet2-advs .adv .adv-title:after,
  .advantages-1 .wrapper .advantage-title:after,
  .advantages-3 .wrapper .advantage-title:after,
  .ideology-items .ideology-item-wrapper .ideology-item-title:after {
    content: '';
    width: 2px;
    height: 14px;
    background: #465256;
    position: absolute;
    top: 9px;
    right: 6px;
  }
  .wallet2 .wrapper .wallet2-advs .adv .adv-text,
  .advantages-1 .wrapper .advantage-text,
  .advantages-3 .wrapper .advantage-text,
  .ideology-items .ideology-item-wrapper .ideology-item-text{
    display: none;
  }
  .dex-video .wrapper {
    width: 100%;
    .player-wrapper .owl-carousel .item-video {
      height: 380px;
    }
  }
  .price {
    .price-items {
      .price-item {
        padding: 10px 0 10px;
        &.item-3 .price-item-xlm {
          top: -15px;
        }
        &.item-1:hover, &.item-2:hover, &.item-3:hover, &.item-4:hover, &.item-5:hover, &.item-6:hover {
          padding-left: 0;
          padding-right: 0;
        }
        .price-item-box-3, .price-item-box-4 {
          margin-top: 10px;
        }
      }
      .price-item-stage {
        font-size: 60px;
      }
      .price-item-desc {
        top: -10px;
      }
      .price-item-xlm {
        font-size: 36px;
      }
      .price-item.item-3 .price-item-discount {
        font-size: 15px;
        padding: 5px;
      }
      .price-item.item-3:hover .price-item-discount {
        padding: 5px;
      }
    }
  }
  .team .wrapper .team-title, .team .wrapper .team-title.inactive {
    font-size: 30px;
  }
  .team .wrapper .team-title:first-child {
    margin-right: 20px;
  }
  .team-wrapper .owl-carousel .owl-nav .owl-prev, .media-wrapper .owl-carousel .owl-nav .owl-prev {
    left: 0px;
    background-color: rgba(255, 255, 255, 0.05);
  }
  .team-wrapper .owl-carousel .owl-nav .owl-next, .media-wrapper .owl-carousel .owl-nav .owl-next {
    right: 0px;
    background-color: rgba(255, 255, 255, 0.05);
  }
  .get-popup, .question-popup, .download-popup {
    &-logo {
      width: 30%;
    }
  }
  .download-popup {
    &-text {
      font-size: 20px;
      line-height: 25px;
    }
  }
}

@media screen and (max-width: 500px) {
  .header .download span {
    display: none;
  }
  .header .download .android-icon {
    margin-left: 0;
  }
  .about-us .about-items {
    width: 40%;
    .about-item {
      width: 100%;
    }
  }
  .footer {
    .footer-logo-image {
      display: none;
    }
    &-button-small {
      position: relative;
      right: 0;
      top: 0;
      margin-bottom: 30px;
    }
    .wrapper .footer-social-icons {
      margin-bottom: 0;
      a {
        width: 18px;
        display: inline-block;
        text-align: center;
        margin-bottom: 40px;
      }
      br {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .about-us .about-items .about-item {
    width: 120px;
    &:before, &:hover:before {
      width: 100%;
      background-size: cover;
    }
    &-1:before {
      height: 20px;
    }
    &-2:before {
      height: 27px;
    }
    &-3:before {
      height: 26px;
    }
    &-4:before {
      height: 40px;
    }
    &-5:before {
      height: 21px;
    }
    &-6:before {
      height: 34px;
    }
    &-7:before {
      height: 22px;
    }
    &-8:before {
      height: 15px;
    }
    &-9:before {
      height: 20px;
    }
    &-10:before {
      height: 9px;
    }
  }
  .dex-video .wrapper .player-wrapper .owl-carousel .item-video {
    height: 300px;
  }
}